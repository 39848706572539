<template>
  <div id="container-principal" class="mt-5">
    <h1 class="text-center mt-3 fw-normal">Dashboard</h1>
    <div class="row mt-4">
      <div class="col-md-6 mb-4">
        <div class="card h-100 p-5">
          <div>
            <highcharts :options="pieChartOptions"></highcharts>
            <p class="text-center mt-3 fw-bold">Total pedidos activos: {{ totalOrders }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card h-100 p-5">
          <div>
            <highcharts :options="barChartOptions"></highcharts>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mb-4">
        <div class="card h-100 d-flex align-items-center justify-content-center">
          <div class="text-center p-5">
            <h1 class="fs-4">Mesas ocupadas: </h1><b class="fs-3" style="color: #f47700">{{ occupiedTables }} / {{ totalTables }}</b>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-4">
        <div class="card h-100 d-flex align-items-center justify-content-center">
          <div class="text-center p-5">
            <h1 class="fs-4">Comandas en curso: </h1><b class="fs-3" style="color: #f47700">{{ ongoingOrders }}</b>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card h-100">
          <div class="text-center">
            <h1 class="pt-4 fs-4">Plato del mes: <b style="color: #f47700">{{ plateOfTheDay.name }}</b></h1>
            <!-- Contenedor para la imagen con clases para bordes redondeados -->
            <div class="image-container">
              <img :src="plateOfTheDay.photo" alt="Plato del día" class="img-fluid responsive-image">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-6 mb-4">
        <div class="card h-100 p-5">
          <div class="text-center">
            <p class="fs-5">Total facturado hoy:</p>
            <h3>{{ totalInvoicedToday.toFixed(2) }} EUR</h3>
            <p style="color: #f47700">{{ comparisonWithYesterday }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card h-100 p-5">
          <div class="text-center">
            <p class="fs-5">Total facturado este mes:</p>
            <h3>{{ totalInvoicedThisMonth.toFixed(2) }} EUR</h3>
            <p style="color: #f47700">{{ comparisonWithLastMonth }}</p>
          </div>
        </div>
      </div>
    </div>
    <SpinnerComponent id="spinner"></SpinnerComponent>
  </div>
</template>

<script>
  /* eslint-disable */
  import Highcharts from "highcharts";
  import { mapState } from 'vuex';
  import axios from 'axios';
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  /* eslint-disable */
  export default {
    components: {
        SpinnerComponent
    },
    data() {
      return {
        totalInvoicedToday: 0,
        totalYesterday: 0,
        totalInvoicedThisMonth: 0,
        totalLastMonth: 0,
        tables: [],
        orders: [],
        plateOfTheDay: {},
        plates: [],
        pieChartOptions: {
          chart: {
            type: 'pie'
          },
          colors: ['#f47700', '#f0ad4e'],
          title: {
            text: null
          },
          series: [{
            name: 'Pedidos',
            data: []
          }],
          credits: {
            enabled: false
          }
        },
        barChartOptions: {
          chart: {
            type: 'column'
          },
          colors: ['#f47700'],
          title: {
            text: 'Ranking Platos más Vendidos'
          },
          xAxis: {
            categories: []
          },
          yAxis: {
            title: {
              text: 'Cantidad Pedida'
            }
          },
          series: [{
            name: 'Platos',
            data: []
          }],
          credits: {
            enabled: false
          }
        }
      };
    },
    computed: {
      ...mapState(['partner_token']),
      occupiedTables() {
        return this.tables.filter(table => table.start_time !== null).length;
      },
      totalTables() {
        return this.tables.length;
      },
      ongoingOrders() {
        return this.orders.filter(order => order.state === 'PENDIENTE' || order.state === 'EN CURSO' ).length;
      },
      totalOrders() {
        return this.orders.length;
      },
      comparisonWithYesterday() {
        return this.totalYesterday < this.totalInvoicedToday
          ? '+' + (this.totalInvoicedToday - this.totalYesterday).toFixed(2) + '€ respecto a ayer'
          : (this.totalInvoicedToday - this.totalYesterday).toFixed(2) + '€ respecto  a ayer';
      },
      comparisonWithLastMonth() {
        return this.totalLastMonth < this.totalInvoicedThisMonth
          ? '+' + (this.totalInvoicedThisMonth - this.totalLastMonth).toFixed(2) + '€ respecto al mes anterior'
          : (this.totalInvoicedThisMonth - this.totalLastMonth).toFixed(2) + '€ respecto al mes anterior';
      }
    },
    async mounted() {
      const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/getDashboard';
      try {
          const response = await axios.get(url, {
              headers: {
              'Authorization': `Bearer ${token}`
              }
          });

          // Maneja la respuesta
          console.log(response.data);
          this.orders = response.data["active_orders"]
          this.plates = response.data["most_selled_plates"]
          this.plateOfTheDay = response.data["best_plate"]
          this.tables = response.data["tables"]
          this.totalInvoicedToday = response.data["today_billed"]
          this.totalYesterday = response.data["yesterday_billed"]
          this.totalInvoicedThisMonth = response.data["month_billed"]
          this.totalLastMonth = response.data["last_month_billed"]
          document.getElementById("spinner").classList.add("d-none")
      } catch (error) {
          // Maneja el error
          document.getElementById("spinner").classList.add("d-none")
          console.error('Error fetching tables:', error);
      }

      const completedOrders = this.orders.filter(order => order.state === 'ENTREGADO' || order.state === 'PAGADO').length;
      const pendingOrders = this.orders.filter(order => order.state != 'ENTREGADO' && order.state != 'PAGADO').length;

      this.pieChartOptions.series[0].data = [
        { name: 'Pedidos completados', y: completedOrders },
        { name: 'Pedidos pendientes', y: pendingOrders }
      ];

      const top5Plates = this.plates.sort((a, b) => b.amount - a.amount).slice(0, 5);
      this.barChartOptions.xAxis.categories = top5Plates.map(plate => plate.name);
      this.barChartOptions.series[0].data = top5Plates.map(plate => plate.amount);
    }
  };
</script>

<style scoped>
  .card {
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .image-container {
    padding: 3rem;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .responsive-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 15px;
  }
  @media (max-width: 576px) {
    #container-principal {
      width: 90%;
      margin: auto;
    }
  }
  @media (min-width: 578px) {
    #container-principal {
      margin-left: 22%;
      width: 77%;
    }
  }
</style>
