<template>
    <div id="order_bill" class="d-none">
      <div @click="Close()" class="h-100 w-100 position-fixed z-1">
      </div>
      <div class="bg-white expanded rounded-4 p-4 z-2">
        <div v-if="initial">
          <h2 class="text-center mb-3">¿Confirmar pedir la cuenta?</h2>
          <p class="fw-semibold px-2">Al presionar "Confirmar", un camarero será notificado y vendrá a traerte la cuenta.</p>
          <div class="d-flex justify-content-center mt-4">
            <p class="btn btn-danger px-4" @click="Close()">Cancelar</p>
            <p class="btn text-white ms-4 px-4" @click="PedirCuenta()" style="background-color: #F47700;">Confirmar</p>
          </div>
        </div>
        <div v-else>
          <h4 class="text-center mb-4">Ya hemos pedido tu cuenta</h4>
          <h6 class="mt-4"><i class="fa-solid fa-money-bill-1-wave text-success me-2"></i> LLegará en breve a su mesa</h6>
        </div>
      </div>
      
    </div>
  </template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'LlamadaCamarero',
  data: function() {
      return {
        initial: true
      }
    },
  components: {
    
  },
  computed:{
    ...mapState(['token'])
  },
  methods: {
    Close(){
      document.getElementById("order_bill").classList.add("d-none")
    },
    async PedirCuenta(){
      console.log("pedir cuenta")
      let url = process.env.VUE_APP_API_URL+'/v1/api/app/orderBill'
      try {
        const response = await axios.post(url, {}, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        console.log(response.data);
        this.initial = false

        setTimeout(() => {
          document.getElementById("order_bill").classList.add("d-none")
          this.initial = true
        }, 3000);
      } catch (error) {
        console.error('Error fetching shopping cart:', error);
      }
    }
  }
}

</script>
  
  <style scoped>
  /* Estilo para el contenedor del spinner */
  #order_bill {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(139, 139, 139, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000 !important;
    transition: all 3s ease
  }

  .expanded{
    width: 90%;
  }
  
  </style>
  