<template>
  <div class="container-fluid d-flex align-items-center justify-content-center min-vh-100">
    <div class="text-center">
      <div class="row d-flex align-items-center justify-content-center">
        <img src="./../assets/unauthorizedError.png" id="imagen" alt="Unauthorized Error">
      </div>
      <p class="text-center fs-5 fw-semibold">
        No está autorizado para entrar a la aplicación <br/>
        <span style="color:#f47600">Contacte con el personal</span>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UnAuthorized',
    mounted() {
      //this.$store.commit('logout');
    }
  }
</script>

<style scoped>
  #imagen {
    width: 80%;
    max-width: 400px;
    height: auto;
  }

  @media (max-width: 768px) {
    #imagen {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    p {
      font-size: 1.25rem;
    }
  }
</style>
