import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

//import vue3GoogleLogin from 'vue3-google-login'
//import gAuthPlugin from 'vue3-google-oauth2';
//import GoogleSignInPlugin from "vue3-google-signin"

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'fontsource-montserrat/700.css';

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdi', // Utiliza el conjunto de íconos de Material Design
  },
})

import "jquery"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
//import 'bootstrap-vue/dist/bootstrap-vue.css';
//import BootstrapVue from 'bootstrap-vue';
import "axios"

import 'bootstrap-icons/font/bootstrap-icons.css'; // Importa el archivo CSS de Bootstrap Icons
import 'bootstrap-icons/font/bootstrap-icons';

import HighchartsVue from 'highcharts-vue';


const app = createApp(App);

  app.use(store)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(ElementPlus, {
    // Establecer isCustomElement en true
    compilerOptions: { isCustomElement: (tag) => tag.startsWith('el-') },
  })
  .use(HighchartsVue)
  .mount('#app');
//});

