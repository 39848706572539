<template>
    <div class="select-menuAddress w-md-75" id="select-menu-provincia">
        <div class="select-btn shadow" id="select-btn-provincia form-control">
            <d class="fa-solid fa-location-dot fs-5"></d>
            <input ref="miInput" autocomplete="off" id="textbox-place" @focus="handleFocus" @blur="handleBlur" v-model.lazy="inputValue" @input="handleInput" class="form-control ms-2" placeholder="Dirección">
        </div>
        <ul class="options z-1" id="options-provincia" style="padding: 0px; overflow-y: scroll; overflow-x: hidden; width: auto; ">
        </ul>
    </div>
    <div class="col-xl-8 col-12 my-3" id="map" style="min-height: 280px; z-index: 0;">
    </div>
</template>
  
<script>
  import axios from 'axios';
  import L from 'leaflet';
  import 'leaflet.markercluster';
  
  export default {
    name: 'AddressBrowserComponent',
    props: {  address: { type: String, required: false }, latitud: { type: String, required: false }, longitud: { type: String, required: false } },
    data: function() {
          return {
            inputValue: "",
            typingTimer: null,
            centro: [38.345997, -0.490686],
            isCorrect: false,
            map: {},
            markers: L.markerClusterGroup(),
          }
    },
    watch:{
        address(){
            this.inputValue = this.address
        },
        latitud(){
            console.log(this.centro)
            let parsedLatitud = Number(this.latitud);
            if (!isNaN(parsedLatitud)) {
                this.centro[1] = parsedLatitud;
            }
            console.log("ya he cambiado la latitud")
            if (typeof this.centro[0] === 'number' && typeof this.centro[1] === 'number'){
                this.MoverMapa()
                this.MeterMarcador(this.centro)
            }
        },
        longitud(){
            console.log(this.centro)
            let parsedLongitud = Number(this.longitud);
            if (!isNaN(parsedLongitud)) {
                this.centro[0] = parsedLongitud;
            }
            console.log("ya he cambiado la longitud")
            if (typeof this.centro[0] === 'number' && typeof this.centro[1] === 'number'){
                this.MoverMapa()
                this.MeterMarcador(this.centro)
            }
        }
    },
    methods: {
        cazado(){
            this.$refs.miInput.classList += " is-invalid"
            this.$refs.miInput.classList.remove("is-valid")
        },
        async AddCitySelects(){
            var provincias = []
            try {
                const response = 
                await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/'+ this.inputValue +'.json?access_token=pk.eyJ1IjoiamJuMjAwMSIsImEiOiJjbGt3cW95NjMwM2JsM2tueWVrMHV0MnJoIn0.nI9ULLd2W_4P0JCku50Iww&country=es&limit=5');
                provincias = response.data.features
            } catch (error) {
                console.error(error);
            }

            const cityUl = document.querySelector("#options-provincia")

            cityUl.innerHTML=""
            
            provincias.forEach(provincia => {
                var li = document.createElement("li")
                li.classList="option"
                li.id="option-provincia"
                cityUl.appendChild(li)

                var div = document.createElement("div")
                div.classList="mx-auto"
                li.appendChild(div)

                var span = document.createElement("p")
                span.classList="option-text pb-1 pt-3"
                span.innerHTML=provincia.place_name;
                div.appendChild(span)
                //li.addEventListener("mousedown", this.clickProvincia);
                li.addEventListener("mousedown", () => {
                    this.$refs.miInput.value = provincia.place_name;
                    this.inputValue = provincia.place_name
                    this.centro = [provincia.center[1], provincia.center[0]]
                    console.log(this.centro)
                    if(!this.$refs.miInput.classList.contains("is-valid")){
                        this.$refs.miInput.classList += " is-valid"
                        this.$refs.miInput.classList.remove("is-invalid")
                        this.isCorrect = true
                    }
                    this.PasarVariables()
                });
            })
        },
        handleInput() {
            
            if(!this.$refs.miInput.classList.contains("is-invalid")){
                this.$refs.miInput.classList += " is-invalid"
                this.$refs.miInput.classList.remove("is-valid")
                this.isCorrect = false
                
            }
            clearTimeout(this.typingTimer);

            // Iniciar un temporizador para verificar si el usuario ha dejado de escribir durante 1 segundo
            this.typingTimer = setTimeout(() => {
                this.onTypingStopped();
            }, 100);
            this.PasarVariables()
        },
        onTypingStopped() {
            this.inputValue = this.$refs.miInput.value
            this.AddCitySelects()
        },
        async handleFocus() {
            await this.AddCitySelects()
            const optionMenuProvincia = document.getElementById("select-menu-provincia")
            optionMenuProvincia.classList.toggle("active");
        },
        handleBlur() {
            // Este evento se ejecutará cuando el input pierda el foco
            const optionMenuProvincia = document.getElementById("select-menu-provincia")
            optionMenuProvincia.classList.toggle("active");
        },
        PasarVariables(){
            if(this.isCorrect){
                this.$emit("getDataBrowser", this.inputValue, this.centro);
                this.MoverMapa()
                this.MeterMarcador(this.centro)
            }
        },
        MeterMarcador(centro){
            var iconoPersonalizado  = L.icon({
                iconUrl: require('@/assets/marcador.png'),  // URL de la imagen del icono
                iconSize: [38, 40],     // Tamaño del icono
                iconAnchor: [22, 40],   // Punto del icono que corresponderá a la ubicación del marcador
                popupAnchor: [-3, -76],
            });
            var marker = L.marker(centro, {icon : iconoPersonalizado});
            this.markers.clearLayers();
            
            this.map.addLayer(this.markers);
            this.markers.addLayer(marker);
        },
        MoverMapa(){
            console.log("Me llega asi: " + this.centro)
            this.map = this.map.setView(this.centro, 15)
        }
    },
    mounted(){
        this.map = L.map('map', {zoomControl: true,zoom:1,zoomAnimation:true,fadeAnimation:true,markerZoomAnimation:true, scrollWheelZoom: true, dragging: true})
        this.MoverMapa()
        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
            maxZoom: 20,
            minZoom: 1
        }).addTo(this.map);
    }
  }
</script>
  
<style>
    /* ===== Google Font Import - Poppins ===== */
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

    #textbox-place{
    border: 0px;
    box-shadow: 0px;
    }

    #textbox-place:focus{
    border: 0px;
    }

    .select-menuAddress{
        width: 100%;
    }

    .select-menuAddress .select-btn{
        display: flex;
        height: 40px;
        background: #fff;
        padding: 20px;
        font-size: 1rem;
        font-weight: 400;
        border-radius: 8px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
        border-radius: 5px;
        color: #c7c7c7;
    }
    .select-btn i{
        font-size: 1rem;
        transition: 0.3s;
        color: #c7c7c7;
    }
    .select-menuAddress.active .select-btn i{
        transform: rotate(-180deg);
    }
    .select-menuAddress .options{
        position: absolute;
        width: auto;
        padding: 20px;
        margin-top: 10px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 3px rgba(0,0,0,0.1);
        display: none;
        
    }
    .select-menuAddress.active .options{
        display: block;
    }
    .options{
        padding: 0px;
        margin: 0px;
        background-color: red;
    }
    .options .option{
        display: flex;
        height: 55px;
        width: 100%;
        cursor: pointer;
        padding: 0px;
        border-radius: 8px;
        align-items: center;
        background: #fff;
    }
    .options .option:hover{
        background: #F2F2F2;
    }
    .option i{
        margin-right: 12px;
        color: #c7c7c7;
    }
    .option .option-text{
        font-size: 1rem;
        color: #333;
        white-space: nowrap;       /* Evita que el texto se divida en varias líneas */
        overflow: hidden;          /* Oculta cualquier contenido que se desborde */
        text-overflow: ellipsis;
        width: 50vw;
        padding: 0px 30px 0px 30px;
    }

    #options-provincia::-webkit-scrollbar{
        width: 5px;
    }

    #options-provincia::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, #c7c7c7 0%, #c7c7c7 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }
    
    .leaflet-control-attribution {
    display: none !important;
    }
</style>