<template>
  <div class="mt-5 pb-5">
    <h1 class="fw-normal my-3 text-center">Datos del restaurante</h1>
    <form id="formulario" class="bg-white p-3 p-sm-5 rounded-5">
      <div class="form-group mb-5">
        <label for="restaurantName">Nombre del restaurante:<span class="text-danger">*</span></label>
        <input type="text" class="form-control w-md-75 shadow ml-2 my-2" id="restaurantName" v-model="restaurantName" maxlength="100"/>
      </div>
      <div class="form-group mb-3 ml-2">
        <!--<label for="address">Ubicación del restaurante:</label>
        <input type="text" class="form-control w-75 shadow ml-2 mt-3" id="address" v-model="address" placeholder="Ej: Calle Santa Fe, 2, Alicante, 03002"/>
        -->
        <address-browser-component @getDataBrowser="getDataBrowser" :address="address" :longitud="longitud" :latitud="latitud" class="shadow ml-2 mt-3"></address-browser-component>
        <!--<p>{{ this.latitud }}</p>
        <p>{{ this.longitud }}</p>-->
        <div class="form-check mt-2 ml-2">
          <input class="form-check-input" type="checkbox" id="required_location" v-model="required_location"/>
          <label class="form-check-label" for="required_location">
            Comprobar la ubicación del comensal para verificar si está en el restaurante
          </label>
        </div>
      </div>
      <h3 class="mt-5 mb-3">Configuración del restaurante</h3>
      <!--
        <div class="form-group mb-4">
          <label for="tableTime" class="form-label">
            Tiempo activo de la mesa (minutos):<span class="text-danger">*</span>
          </label>
          <div class="d-flex align-items-center">
            <input type="number" v-model="tableTime" class="form-control text-center" id="tableTime" min="0" style="width: 80px;" />
            <button class="btn btn-outline-secondary mx-2" @click.prevent="decreaseTableTime">-</button>
            <button class="btn btn-outline-secondary" @click.prevent="increaseTableTime">+</button>
          </div>
        </div>
      -->
      <div class="form-group mt-4">
        <div class="row">
          <div class="col form-label">
            <label for="horarioCocina">
              Horario de cocina:<span class="text-danger">*</span>
            </label>
            <button type="button" style="color:#ff7a00" @click.prevent="addSchedule">
              <img src="../assets/icons8-más-50.png" class="w-50"/>
            </button>
          </div>
        </div>
        <div v-for="(schedule, index) in schedules" :key="index" class="d-flex align-items-center mb-2 ml-2">
          <div class="d-flex align-items-start d-sm-none" id="time-container">
            <span class="mr-2 mt-1">de</span>
            <input type="time" class="form-control time-input" v-model="schedule.inicio"/>
            <span class="mx-2 mt-1">a</span>
            <input type="time" class="form-control time-input" v-model="schedule.fin"/>
          </div>
          <div class="d-none d-sm-flex align-items-center flex-wrap mb-2">
            <span class="mr-2">de</span>
            <input type="time" class="form-control mx-2 text-center time-input" v-model="schedule.inicio"/>
            <span class="mr-2">a</span>
            <input type="time" class="form-control mx-2 text-center time-input" v-model="schedule.fin"/>
          </div>
          <button v-if="schedules.length > 1 && index > 0" class="btn" @click.prevent="removeSechedule(index)">
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>
      <!-- PARTE DEL TPV
      <div class="form-group mb-3 d-flex flex-wrap flex-md-nowrap">
        <div class="w-100 w-md-50">
          <label for="tpv" class="my-2">TPV:</label>
          <select id="tpv" class="form-select shadow ml-2" v-model="tpv">
            <option value="value">Value</option>
          </select>
        </div>
        <div class="w-100 w-md-50 mt-3 mt-md-0">
          <label for="tpvKey" class="my-2">Clave TPV:</label>
          <input type="text" class="form-control shadow ml-2" id="tpvKey" placeholder="ABCD-1234-EFGH-5678" v-model="tpvKey" maxlength="50">
        </div>
      </div>
      -->

      <!-- PARTE DE LA FORMA DE PAGO
        <div class="form-group mb-3">
          <label class="d-block">Forma de pago:<span class="text-danger">*</span></label>
          <div class="form-check">
            <input class="form-check-input custom-radio" type="radio" name="payOption" id="pagoCadaPedido" value="pagoCadaPedido"/>
            <label class="form-check-label" for="pagoCadaPedido">
              Pago en cada pedido
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input custom-radio" type="radio" name="payOption" id="pagoAlFinal" value="pagoAlFinal" v-model="payOption"/>
            <label class="form-check-label" for="pagoAlFinal">
              Pago al final
            </label>
          </div>
        </div>
      -->
      <div class="form-group mb-3">
        <div class="d-flex align-items-center">
          <label class="d-block me-2">Modo Avanzado</label>
          <div class="form-check form-switch">
            <input class="form-check-input custom-slider" style="width:50px" type="checkbox" id="advanceMode" v-model="advanceMode"/>
            <label class="form-check-label" for="advanceMode"></label>
          </div>
        </div>
        <small class="text-muted">
          El modo Avanzado de Eat with us te permite crear modos de uso de la plataforma adaptados a tu restaurante.
        </small>
      </div>
      <div v-if="advanceMode" id="modes-list">
        <div class="d-flex overflow-x-auto custom-scroll">
          <div v-for="mode in modes" :key="mode.id" class="card text-center p-3 mx-2 mb-2 rounded-5 shadow" style="min-width: 230px; max-width: 220px; height: 130px; border: 1px solid #eee; background-color: #f7f7f7; overflow: hidden;">
            <div class="d-flex flex-column g-0" style="height: 100%; width: 100%;">
              <div class="col d-flex flex-column justify-content-center p-2 mt-2">
                <div class="p-0">
                  <h3 class="mb-4" style="font-size: 1.2rem; text-align: center; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    {{ mode.name }}
                  </h3>
                  <div class="card-actions mt-2 d-flex align-items-center justify-content-center">
                    <i class="fa-solid fa-pencil mr-2 action-icon" @click="openEditModeModal(mode)" style="color: #f47700;"></i>
                    <i class="fa-regular fa-trash-can action-icon" @click="openDeleteModeModal(mode)" style="color: #f47700;"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-3">
          <button type="button" @click="openModalNewMode" class="btn btn-outline-secondary">Crear Modo</button>
        </div>
      </div>
      <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
      <div class="d-flex align-items-center justify-content-center mt-3">
        <button type="button" class="btn btn-secondary" @click="saveRestaurantConfiguration()">Guardar</button>
      </div>
    </form>
    <!-- POPUP CONFIRMACIÓN PARA ORDENADOR (ABAJO DELECHA) -->
    <div v-if="showConfirmation" class="position-fixed bottom-0 end-0 bg-white border shadow-lg p-3 rounded mb-3 mr-5 d-none d-md-flex align-items-center" style="z-index: 1000;">
      <h6 class="mt-2"><i class="fa-solid fa-circle-check text-success"></i> Configuración actualizada con éxito</h6>
    </div>

    <!-- POPUP CONFIRMACIÓN PARA MOVIL (ALIBA CENTLO) -->
    <div v-if="showConfirmation" class="position-fixed top-0 start-0 w-100 h-100 d-md-none d-flex align-items-center justify-content-center" style="background-color: rgba(139, 139, 139, 0.5); z-index: 1000;">
      <div class="bg-white border shadow-lg p-3 rounded w-100 mx-3">
        <h6 class="mt-2"><i class="fa-solid fa-circle-check text-success"></i> Configuración actualizada con éxito</h6>
      </div>
    </div>
    <NewModeModalComponent
      :isOpen="showAddMode" 
      @close="closeAddModeModal" 
      @guardar="addMode"
    />
    <EditModeModalComponent
      :isOpen="showEditMode" 
      :modoData="selectedMode"
      @close="closeEditModeModal" 
      @actualizar="updateMode"
    />
    <DeleteModeModalComponent
      :showModal="showDeleteMode"
      :mode="selectedMode"
      @close="closeDeleteModeModal"
      @confirm-delete="deleteMode"
    />
    <SpinnerComponent id="spinner"></SpinnerComponent>
  </div>
</template>

<script>
  import NewModeModalComponent from '@/components/NewModeModalComponent.vue';
  import EditModeModalComponent from '@/components/EditModeModalComponent.vue';
  import DeleteModeModalComponent from '@/components/DeleteModeModalComponent.vue';
  import AddressBrowserComponent from '@/components/AddressBrowserComponent.vue';
  import SpinnerComponent from '../components/SpinnerComponent.vue';

  import axios from 'axios';
  import { mapState } from 'vuex';
  import { v4 as uuidv4 } from 'uuid';

  export default {
    components: {
      NewModeModalComponent,
      EditModeModalComponent,
      DeleteModeModalComponent,
      AddressBrowserComponent,
      SpinnerComponent
    },
    data() {
      return {
        restaurantName: '',
        address: '',
        required_location: false,
        tableTime: 120,
        schedules: [{ inicio: '00:00', fin: '00:00' }],
        tpv: '',
        tpvKey: '',
        advanceMode: false,
        modes: [],
        selectedMode: null,
        editingMode: null,
        showAddMode: false,
        showEditMode: false,
        showDeleteMode: false,
        showConfirmation: false,
        errorMessage: '',
        latitud: null,
        longitud: null,
        first_charge:true,
      };
    },
    methods: {
      getDataBrowser(direccion, coords){
        this.address = direccion
        console.log(direccion)
        console.log(coords)
        this.latitud = String(coords[1])
        this.longitud = String(coords[0])
      },
      async loadRestaurantConfig() {
        const url = process.env.VUE_APP_API_URL+'/v1/api/partner/get_restaurant_config';
        try {
          const response = await axios.get(url, {
            headers: {
              'Authorization': `Bearer ${this.partner_token}`,
            }
          });

          const data = response.data;
          this.restaurantName = data.name || '';
          this.address = data.address || '';
          this.latitud = data.latitude || '';
          this.longitud = data.longitude || '';
          this.required_location = data.required_location;
          this.tableTime = data.table_time || 120;
          this.schedules = (data.schedules || [{ start_hour: '00:00', final_hour: '00:00' }])
          .map(schedule => ({
            inicio: schedule.start_hour || '00:00',
            fin: schedule.final_hour || '00:00'
          }));
          this.tpv = data.TPV_type || '';
          this.tpvKey = data.TPV_token || '';
          this.advanceMode = data.advance_mode || false;
          this.modes = (data.modes || []).map(mode => ({
            id: mode.id || uuidv4(),
            name: mode.name || '',
            discount: mode.discount || 0,
            platesMax: mode.plates_person || 0,
            excludedCategories: mode.excluded_categories || [],
            priceBlocks: (mode.initial_prices || []).map(price => ({
                adultPrice: price.adults_price || 0,
                childPrice: price.children_price || 0,
                horaInicio: price.schedule.start_hour || '00:00',
                horaFin: price.schedule.final_hour || '00:00',
                selectedDays: price.schedule.days ? price.schedule.days.split(', ') : []
            }))
        }));

        if(this.first_charge){
          document.getElementById("spinner").classList.add("d-none")
          this.first_charge=false
        }

        } catch (error) {
          document.getElementById("spinner").classList.add("d-none")
          console.error('Error loading restaurant config:', error);
        }
      },
      updateAddress(newAddress, isCorrect, latitude, longitude) {
        this.address = newAddress;
        this.latitude = latitude;
        this.addressCorrect = isCorrect;
        this.longitude = longitude;
      },
      increaseTableTime() {
        this.tableTime += 5;
      },
      decreaseTableTime() {
        if (this.tableTime > 5) this.tableTime -= 5;
      },
      addSchedule() {
        this.schedules.push({ inicio: '', fin: '' });
      },
      removeSechedule(index) {
        this.schedules.splice(index, 1);
      },
      openModalNewMode() {
        this.showAddMode = true;
      },
      closeAddModeModal() {
        this.showAddMode = false;
      },
      addMode(mode) {
        const newMode = {
          ...mode,
          id: uuidv4()
        };
        this.modes.push(newMode);
        this.closeAddModeModal();
      },
      openEditModeModal(mode) {
        this.selectedMode = mode;
        this.editingMode = JSON.parse(JSON.stringify(mode));
        this.showEditMode = true;
      },
      closeEditModeModal() {
        this.showEditMode = false;
        this.selectedMode = null;
        this.editingMode = null;
      },
      updateMode(updatedMode) {
        const index = this.modes.findIndex(m => m.id === this.selectedMode.id);
        if (index !== -1) {
            this.modes.splice(index, 1, { ...this.selectedMode, ...updatedMode });
        }
        this.closeEditModeModal();
      },
      openDeleteModeModal(mode) {
        this.selectedMode = mode;
        this.showDeleteMode = true;
      },
      closeDeleteModeModal() {
        this.showDeleteMode = false;
      },
      deleteMode() {
        this.modes = this.modes.filter(m => m.id !== this.selectedMode.id);
        this.closeDeleteModeModal();
      },
      async saveRestaurantConfiguration() {
        if(!this.restaurantName) {this.errorMessage = "Debes indicar un nombre para el restaurante"; return;}
        //console.log(this.addressCorrect + " sadfasdas")
        //if(!this.addressCorrect) {this.errorMessage = "La dirección del restaurante no es correcta"; return;}
        const hasInvalidSchedules = this.schedules.some(schedule => {
          if (!schedule.inicio || !schedule.fin) {
            return true;
          }
          return false;
        });
        if(hasInvalidSchedules) {this.errorMessage = "Debes indicar un horario de cocina válido"; return;}
        const url = process.env.VUE_APP_API_URL+'/v1/api/partner/update_restaurant_config';
        const configData = {
            name: this.restaurantName,
            address: this.address,
            latitude: this.latitud,
            longitude: this.longitud,
            required_location: this.required_location,
            TPV_type: this.tpv,
            TPV_token: this.tpvKey,
            advance_mode: this.advanceMode,
            schedules: this.schedules.map(schedule => ({
                start_hour: schedule.inicio,
                final_hour: schedule.fin,
                days: "LMXJVSD"
            })),
            modes: this.modes.map(mode => ({
                id: mode.id,
                name: mode.name,
                discount: mode.discount || 0,
                plates_person: mode.platesMax || 0,
                excluded_categories: mode.excludedCategories || [],
                initial_prices: mode.priceBlocks.map(block => ({
                    adults_price: block.adultPrice || 0,
                    children_price: block.childPrice || 0,
                    schedule: {
                        start_hour: block.horaInicio,
                        final_hour: block.horaFin,
                        days: block.selectedDays.join(", ") || ""
                    }
                }))
            }))
        };

        try {
          document.getElementById("spinner").classList.remove("d-none")
          const response = await axios.put(url, configData, {
            headers: {
                'Authorization': `Bearer ${this.partner_token}`,
                'Content-Type': 'application/json'
            }
          });
          console.log("Response: " + response)
          this.showConfirmation = true;
          this.loadRestaurantConfig();
          setTimeout(() => {
            this.showConfirmation = false;
          }, 3000);
          this.errorMessage = '';
          document.getElementById("spinner").classList.add("d-none")
        } catch (error) {
          console.error('Error al guardar la configuración del restaurante:', error);
          document.getElementById("spinner").classList.add("d-none")
        }
      },
    },
    computed: {
      ...mapState(['partner_token'])
    },
    mounted() {
      this.loadRestaurantConfig();
    }
  };
</script>

<style scoped>
  .custom-slider {
    border-radius: 34px;
    width: 50px;
    height: 26px;
    position: relative;
  }

  .custom-slider:checked {
    background-color: #ff7a00;
  }

  .custom-radio:checked {
    background-color: #ff7a00;
  }

  #modes-list {
    white-space: nowrap;
    overflow-x: auto;
  }

  .custom-scroll::-webkit-scrollbar {
    height: 5px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }

  #time-container {
    flex-direction: row;
  }

  .time-input {
    margin-bottom: 0;
    width: auto;
  }

  #time-container span {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    .container {
      padding: 0 15px;
    }
  }
</style>