<template>
  <div v-if="showModal" class="modal fade show d-block z-3 d-flex align-items-center justify-content-center" tabindex="-1">
    <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
    <div class="modal-dialog z-2 m-4 d-flex align-items-center justify-content-center">
      <div class="modal-content p-2 custom-scroll">
        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
          <p class="modal-title px-2 fw-bold fs-5 text-center">Editar a </p>
        </div>
        <p class="fw-bold text-center"> "{{ localEmployee.username }}"</p>
        <div class="modal-body">
          <form @submit.prevent="submitEdit">
            <div class="row">
              <span id="span-error" class="text-danger text-center pb-3"></span>
              <div class="col-md-6 mb-5">
                <div class="form-group">
                  <b for="table-name">UserName:</b><br />
                  <input type="text" id="name" v-model="localEmployee.username" class="form-control shadow rounded-3" maxlength="50"/>
                </div>
              </div>

              <div class="col-md-6 mb-5">
                <div class="form-group">
                  <b for="employee-password">Contraseña:</b><br />
                  <input type="password" id="employee-password" v-model.number="localEmployee.password" class="form-control shadow rounded-3" maxlength="30"/>
                </div>
              </div>
              <div class="col-12 mb-5">
                <div class="form-group">
                  <b for="employee-password">Email:</b><br />
                  <input type="text" id="employee-password" v-model.number="localEmployee.email" class="form-control shadow rounded-3"/>
                </div>
              </div>
            </div>

            <div class="modal-footer align-items-center justify-content-center border-none">
              <button type="submit" class="btn btn-add text-white px-4 rounded-3" style="background-color: #f47700">Editar</button>
              <button type="button" @click="closeModal" class="btn btn-danger px-4 rounded-3">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    props: {
      showModal: Boolean,
      employee: Object,
    },
    data() {
      return {
        localEmployee: { ...this.employee },
      };
    },
    methods: {
      closeModal() {
        this.$emit('close-modal');
      },
      submitEdit() {
        if (this.localEmployee.username == "" || this.localEmployee.email == "") {
          document.getElementById("span-error").textContent = "Debes rellenar todos los campos para continuar"
          return;
        }
        this.$emit('update-employee', this.localEmployee);
      },
    },
    watch: {
      employee(newEmployee) {
        this.localEmployee = { ...newEmployee };
      },
    },
  };
</script>
  
<style scoped>
  .modal { 
    background-color: rgba(139, 139, 139, 0.5); 
  }

  .modal-dialog {
    height: 90vh; 
    min-width: 60%;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 90vh;
  }

  @media (min-width: 767px) {
    .modal-dialog {
      position: absolute;
      width: 80%;
      left: 28%;
      min-height: 90vh; 
      min-width: 60%; 
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  
  .custom-scroll {
    padding-top: 10px;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 7px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }
</style>