<template>
  <div v-if="showModal" class="modal fade show d-block z-3 d-flex align-items-center justify-content-center" tabindex="-1">
    <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
    <div class="modal-dialog z-2 m-4 d-flex align-items-center justify-content-center">
      <div class="modal-content p-2 custom-scroll">
        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
          <h3 class="modal-title">Nuevo Empleado</h3>
        </div>
        <div class="modal-body">
          <form @submit.prevent="confirmAdd">
            <div class="row">
              <span id="span-error" class="text-danger text-center pb-3"></span>
              <div class="col-md-6 mb-5">
                <div class="form-group">
                  <b for="employee-name">Nombre: *</b><br />
                  <input type="text" id="employee-name" v-model="newEmployee.username" class="form-control shadow rounded-3" autocomplete="new-username" name="employee-username" placeholder="Ejemplo: José Gutierrez" maxlength="50"/>
                </div>
              </div>

              <div class="col-md-6 mb-5">
                <div class="form-group">
                  <b for="employee-password">Contraseña: *</b><br />
                  <input type="password" id="employee-password" v-model.number="newEmployee.password" class="form-control shadow rounded-3" autocomplete="new-password" name="employee-password"  placeholder="EWU@2024" min="1" maxlength="30" />
                </div>
              </div>
              <div class="col-12 mb-5">
                <div class="form-group">
                  <b for="employee-password">Email:</b><br />
                  <input type="text" id="employee-password" v-model.number="newEmployee.email" class="form-control shadow rounded-3" autocomplete="new-email" name="employee-email"/>
                </div>
              </div>
            </div>

            <div class="modal-footer align-items-center justify-content-center border-none">
              <button type="submit" class="btn btn-add text-white px-4 rounded-3" style="background-color: #f47700">Añadir</button>
              <button type="button" @click="closeModal" class="btn btn-danger px-4 rounded-3">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
      props: {
          showModal: {
              type: Boolean,
              required: true,
          },
      },
      data() {
          return {
              newEmployee: {
                username: "",
                password: "",
                email: ""
              },
          };
      },
      methods: {
          closeModal() {
              this.$emit('close-modal');
          },
          confirmAdd() {
              if (this.newEmployee.username == "" || this.newEmployee.email == "" || this.newEmployee.password == "") {
                  document.getElementById("span-error").textContent = "Debes rellenar todos los campos para continuar"
                  return;
              }
              if (this.newEmployee.password.length < 8){
                  document.getElementById("span-error").textContent = "La contraseña deber tener 8 o mas caracteres"
                  return;
              }

              this.$emit('confirm-add', { ...this.newEmployee });
          },
          clean(){
            this.newEmployee.username = ""
            this.newEmployee.email = ""
            this.newEmployee.password = ""
          }
      },
  };
</script>
  
<style scoped>
  .modal { 
    background-color: rgba(139, 139, 139, 0.5); 
  }

  .modal-dialog {
    height: 90vh; 
    min-width: 60%;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 90vh;
  }

  @media (min-width: 767px) {
    .modal-dialog {
      position: absolute;
      width: 80%;
      left: 28%;
      min-height: 90vh; 
      min-width: 60%; 
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .custom-scroll {
    padding-top: 10px;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 7px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }
</style>