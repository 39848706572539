<template>
    <div id="container-principal">
        <h1 class="fw-normal mt-5 text-center">Listado de mesas</h1>
        <p class="text-center">Nº total de mesas: {{ tables.length }}</p>
        <div class="d-flex justify-content-end mb-3">
            <button class="btn btn-add d-none d-md-block px-5" @click="showAddTableModal">Añadir</button>
        </div>
        <div v-if="tables.length > 0" class="row d-flex align-items-center justify-content-center">
            <div class="col-12 col-md-6 col-lg-4 mb-3" v-for="table in tables" :key="table.id">
                <div class="card h-100">
                    <div class="d-flex flex-column flex-md-row g-0">
                        <div class="col-12 d-flex flex-column p-0 position-relative">
                            <div class="card-actions d-md-none position-absolute" style="top: 0; right: 0;">
                                <i class="fa-solid fa-pencil mr-2 action-icon" style="color: #f47700;" @click="showEditModal(table)"></i>
                                <i class="fa-regular fa-trash-can action-icon" style="color: #f47700;" @click="showDeleteModal(table)"></i>
                            </div>
                            <div class="row mt-4 pt-2 d-md-none">
                                <h3 class="table-name" style="font-size: 1.3rem; text-align: center;">{{ table.number }}</h3>
                            </div>
                            <div class="d-flex align-items-center p-0 flex-wrap">
                                <div class="col-6 col-md-4 d-flex">
                                    <img :src="table.image || defaultImageUrl" class="img-fluid" alt="Table Image" />
                                </div>
                                <div class="col-6 col-md-8 d-flex flex-column justify-content-center p-2 mt-2">
                                    <div class="p-0">
                                        <h3 class="table-name d-none text-center d-md-block" style="font-size: 1.5rem;">{{ table.number }}</h3>
                                        <p class="diners-count" style="font-size: 1rem; text-align: center;">Nº de comensales: {{ table.max_diners }}</p>
                                        <p style="font-size: 0.7rem;" class="my-2">https://ewu.es/loginQr/{{table.id}}</p>
                                        <div class="card-actions d-none d-md-block mt-2 d-flex align-items-center justify-content-center">
                                            <i class="fa-solid fa-pencil mr-2 action-icon" style="color: #f47700;" @click="showEditModal(table)"></i>
                                            <i class="fa-regular fa-trash-can action-icon" style="color: #f47700;" @click="showDeleteModal(table)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h5 v-else class="text-center mt-5">No hay mesas creadas aún</h5>
        
        <div class="d-flex aling-items-center justify-content-center">
            <button class="btn btn-add w-75 d-md-none mb-3 text-center" @click="showAddTableModal">Añadir</button>
        </div>

        <EditTableModalComponent
            :show-modal="showEdit"
            :table="selectedTable"
            @close-modal="closeEditModal"
            @update-table="updateTable"
        />

        <DeleteTableModalComponent
            :show-modal="showDelete"
            :table="selectedTable"
            @close-modal="closeDeleteModal"
            @confirm-delete="confirmDeleteTable"
        />

        <AddTableModalComponent
            :show-modal="showAdd"
            @close-modal="closeAddModal"
            @confirm-add="addTable"
        />
        <SpinnerComponent id="spinner"></SpinnerComponent>
    </div>
</template>

<script>
    import EditTableModalComponent from '../components/EditTableModalComponent.vue';
    import DeleteTableModalComponent from '../components/DeleteTableModalComponent.vue';
    import AddTableModalComponent from '../components/AddTableModalComponent.vue';
    import { mapState } from 'vuex';
    import axios from 'axios';
    import SpinnerComponent from '../components/SpinnerComponent.vue'
    import { v4 as uuidv4 } from 'uuid';

    export default {
        components: {
            EditTableModalComponent,
            DeleteTableModalComponent,
            AddTableModalComponent,
            SpinnerComponent
        },
        data() {
            return {
                tables: [],
                selectedTable: null,
                showEdit: false,
                showDelete: false,
                showAdd: false,
                defaultImageUrl: require('@/assets/imagenMesaPredefinida.jpg'),
            };
        },
        computed: {
        ...mapState(['partner_token'])
        },
        methods: {
            showEditModal(table) {
                this.selectedTable = { ...table };
                this.showEdit = true;
            },
            closeEditModal() {
                this.showEdit = false;
                this.selectedTable = null;
            },
            showDeleteModal(table) {
                this.selectedTable = { ...table };
                this.showDelete = true;
            },
            closeDeleteModal() {
                this.showDelete = false;
                this.selectedTable = null;
            },
            showAddTableModal() {
                this.showAdd = true;
            },
            closeAddModal() {
                this.showAdd = false;
            },
            async addTable(newTable) {
                let table = {
                    id: String(uuidv4()),
                    number: newTable.number,
                    max_diners: newTable.max_diners
                }
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/tableCreate';
                try {
                    const response = await axios.post(url, table, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getMesas()
                } catch (error) {
                    console.error('Error fetching shopping cart:', error);
                }
                this.closeAddModal();
            },
            async updateTable(updatedTable) {
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/tableUpdate';
                try {
                    const response = await axios.put(url, updatedTable, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getMesas()
                } catch (error) {
                    console.error('Error fetching shopping cart:', error);
                }
                this.closeEditModal();                
            },
            async confirmDeleteTable(id) {
                console.log(id)
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/tableDelete?table_id='+id;
                console.log(url)
                try {
                    const response = await axios.delete(url, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getMesas()
                } catch (error) {
                    console.error('Error fetching shopping cart:', error);
                }
                this.closeDeleteModal();
            },
            async getMesas(){
                const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/PanelTables';
                try {
                    const response = await axios.get(url, {
                        headers: {
                        'Authorization': `Bearer ${token}`
                        }
                    });

                    // Maneja la respuesta
                    console.log(response.data);
                    this.tables = response.data.tables;
                    this.tables = this.tables.sort((a, b) => {
                        return a.number.localeCompare(b.number);
                    });
                    document.getElementById("spinner").classList.add("d-none")
                } catch (error) {
                    // Maneja el error
                    this.tables = []
                    document.getElementById("spinner").classList.add("d-none")
                    console.error('Error fetching tables:', error);
                }
            }
        },
        created() {
            this.getMesas()
        },
    };
</script>

<style scoped>
    .card {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 16px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    .table-name {
        color: #f57c00;
        font-weight: bold;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        height: 2rem;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.5;
        max-height: calc(1.5em * 2);
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-word;
    }

    .diners-count {
        margin: 0;
        color: #333;
        font-size: 1.2em;
    }

    .card-actions {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .action-icon {
        font-size: 2rem;
        color: #f47700;
        cursor: pointer;
    }

    .btn.btn-add {
        background-color: #f47700;
        color: white;
        font-size: 1.25rem;
        border-radius: 8px;
        height: 40px;
        border: none;
        cursor: pointer;
    }

    @media (min-width: 768px) {
        .card-actions {
            position: static;
            text-align: center;
        }
        .action-icon {
            margin-inline: 1rem;
        }
        #container-principal{
            margin-left:22%; 
            width: 77%;
        }
    }

    @media (max-width: 576px) {
        .action-icon {
            font-size: 1.3rem;
        }
        #container-principal{
            width: 90%;
            margin: auto;
        }
    }
</style>