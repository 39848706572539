<template>
    <div v-if="showModal" class="modal d-block" style="background-color: rgba(139, 139, 139, 0.5);" tabindex="-1" role="dialog" @click.self="closeModal">
      <div class="modal-dialog modal-dialog-centered modal-centered" role="document">
        <div class="modal-content p-4 rounded-4 position-relative">
          <button type="button" class="btn-close position-absolute top-0 end-0 m-3" @click="closeModal" aria-label="Close"></button>
          <div class="modal-header border-0 justify-content-center">
            <h5 class="modal-title fw-bold fs-3">Activar mesa</h5>
          </div>
          <div class="modal-body text-center">
            <p class="fs-5">Seleccione el modo de la mesa:</p>
            <div class="d-flex justify-content-center my-4" >
              <button @click="setTableMode(null)" class='btn typeTableButton mx-2 fw-bold fs-4 shadow w-50 rounded-3'>Normal</button>
              <button @click="setTableMode(mode.id)" class='btn typeTableButton mx-2 fw-bold fs-4 shadow w-50 rounded-3' v-for="mode in modes" :key="mode.id">{{mode.name}}</button>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button type="button" class="btn text-white w-50 shadow-none rounded-3 fs-5" style="background-color: #f47700;" @click="confirmActivation">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
    export default {
      props: {
        showModal: {
          type: Boolean,
          required: true,
        },
        activateTableId: {
          type: Number,
          required: false,
        },
        modes: {
          type: [],
          required: false,
        }
      },
      data() {
        return {
          tableMode: 'Normal',
        };
      },
      methods: {
        closeModal() {
          this.$emit('close-modal');
        },
        setTableMode(mode) {
          this.tableMode = mode;
        },
        confirmActivation() {
          console.log("MODE: " + this.tableMode)
          if (!this.tableMode && this.tableMode != null) {
            document.getElementById("span-error").textContent = "Debes seleccionar un modo antes de continuar"
            return;
          }
          this.$emit('confirm-activation', { id: this.activateTableId, mode: this.tableMode });
        }
      }
    };
</script>
  
<style scoped>
    .modal-dialog {
      max-width: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    
    .modal-centered {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      padding: 1rem; 
    }

    @media (max-width: 576px) {
      .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
      }
    }
    
    .typeTableButton {
      color: black;
      background-color: whitesmoke;
    }
  
    .typeTableButton:active,
    .typeTableButton:focus {
      background-color: #f47700;
      border-color: #f47700;
      color: white;
      box-shadow: none;
    }
</style>
