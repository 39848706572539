<template >
  <div class="mt-5 p-0">
    <div class="row">
      <img src="../../src/assets/EWU_Negro.png" class="col-md-4 col-sm-6 col-7 mx-auto"/> 
    </div>
    <div v-if="!changed" class="mt-5 col-xl-5 col-lg-7 col-md-8 col-11 mx-auto p-5 rounded-2 border" style="background-color: #e7e7e7">
      <h3 class="text-center">Hola, {{ username }}</h3>
      <h5 class="text-center mt-3">Ya puedes cambiar tu contraseña</h5>
      <div class="form-floating mt-4">
        <input type="password" class="form-control fs-3" id="floatingInput" v-model="password" maxlength="30">
        <label for="floatingInput">Nueva Contraseña</label>
      </div>
      <div class="form-floating mt-4">
        <input type="password" class="form-control fs-3" id="floatingInput" v-model="second_password" maxlength="30">
        <label for="floatingInput">Repite la contraseña</label>
      </div>
      <div class="mt-3">
        <span class="text-danger" v-if="password.length<8">* La contraseña debe tener al menos 8 caracteres</span>
        <span class="text-danger" v-else-if="password != second_password">* La contraseñas no coinciden</span>
        <span class="text-success" v-else>Contraseñas correctas</span>
      </div>
      <div class="row mt-3 m-0">
        <button  @click="ChangePass" 
        class="btn btn-dark col-xl-4 col-md-7 col-sm-9 mx-auto" :disabled="password.length < 8 || password !== second_password"
        >Guardar</button>
      </div>
    </div>
    <div v-else class="mt-5 col-xl-5 col-lg-7 col-md-8 col-11 mx-auto p-5 rounded-2 border" style="background-color: #e7e7e7">
      <img src="@/assets/tick.gif" alt="Email enviada" class="img-fluid w-25" />
      <h3 class="text-success mb-3">¡Contraseña cambiada!</h3>
      <p>Tu contraseña ha sido cambiada, ahora tienes que volver a iniciar sesion</p>
    </div>
  </div>
  <SpinnerComponent id="spinner"></SpinnerComponent>
</template>

<script>
  import axios from 'axios';
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  import router from '@/router';
  import { mapState } from 'vuex';

  export default {
    name: 'LoginPage',
    data: function() {
      return {
        token: "",
        username: "",
        password: "",
        second_password: "",
        changed: false
      }
    },
components:{
  SpinnerComponent
},
computed:{
    ...mapState(['partner_token']),
},
async mounted() {
    if (this.$route.params.token){
      this.token = this.$route.params.token
    }else {
      this.token = this.partner_token
    }
    
    const url = process.env.VUE_APP_API_URL+'/v1/api/partner/me';
    try {
        const response = await axios.get(url, {
            headers: {
            'Authorization': `Bearer ${this.token}`
            }
        });
        this.username = response.data.username
        document.getElementById("spinner").classList.add("d-none")
  
    } catch (error) {
        // Maneja el error
        router.push({ name: "Unauthorized" });
        console.error('Error fetching tables:', error);
    }
},
methods:{
  async ChangePass(){
    if (this.password.length < 8 || this.password != this.second_password){
      return
    }

    var spinner = document.getElementById("spinner")
    spinner.classList.remove("d-none")
    
    const apiUrl = process.env.VUE_APP_API_URL+'/v1/api/partner/ChangePass?password=' + this.password;
    axios.post(apiUrl, {}, {
        headers: {
        'Authorization': `Bearer ${this.token}`
        }
    })
    .then(response => {
      console.log(response)
      this.changed = true
      spinner.classList.add("d-none")
      setTimeout(() => {
        this.password = "",
        this.second_password = ""
        this.changed = false
        router.push({ name: "Login" });
      }, 5000);
    })
    .catch(error => {
      console.error(error);
      this.password = "",
      this.second_password = ""
      this.changed = false
      this.response = "El email no corresponde a ningun cliente"
      spinner.classList.add("d-none")
    });
  }

}
}

</script>

<style scoped>
  
</style>