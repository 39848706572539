<template>
  <div class="row m-0 p-0">
    <div class="col-lg-6 col-md-4 d-md-block d-none px-0" >
      <img class="login-image" src="../../src/assets/login-image.jpg" style="height: 100%;" />
    </div>
    <div class="d-flex align-items-center col-lg-6 col-md-8 col-12 login-div pb-5" style="background-color: #F9F9F9 !important;">
      <form autocomplete="off" @submit.prevent="IniciarSesion()">
        <div class="d-flex justify-content-center mb-5">
          <img src="../../src/assets/EWU_Negro.png" class="col-10" />
        </div>
        <div class="mt-4">
          <h4 class="text-center fw-bold">{{ saludo }}</h4>

          <div class="login-input mx-3 mt-3">
              <i class="fa-solid fa-user "></i>
              <input placeholder="Introduzca su email" v-model="userLogin.email" autocomplete="new-username" name="employee-username">
          </div>
          <div class="login-input mx-3 mt-3">
              <i class="fa-solid fa-lock "></i>
              <input type="password" placeholder="Introduzca su contraseña" v-model="userLogin.password" autocomplete="new-password" name="employee-password" maxlength="30"/>
          </div>
          <p class="mt-4 text-center alert alert-danger py-1 w-75 mx-auto fw-semibold d-none" id="incorrect" style="font-size: 1.3rem;">
            Credenciales incorrectas
          </p>

          <div class="d-flex justify-content-center mt-4 w-100">
            <button class="btn text-white px-5 fw-bold" style="background-color: #F47700; font-size: 1.3rem;" type="submit">
              Iniciar Sesión
            </button>
          </div>

          <div class="decorative-text mt-4 pt-2">
            <a @click="changing_pass=true" style="cursor: pointer;">¿Olvidaste la contraseña?</a>
          </div>
        </div>
      </form>
      <spinner-component id="spinner" class="d-none"></spinner-component>
    </div>
    <div v-if="changing_pass" class="modal fade show d-block z-3" tabindex="-1">
        <div @click="changing_pass = false" class="h-100 w-100 position-fixed z-1"></div>
        <div class="modal-dialog d-flex align-items-center justify-content-center z-2">
            <div class="modal-content p-5">
              <div v-if="!sended">
                <h6 class="mb-3">Introduce tu email:</h6>
                <div class="form-floating mb-3">
                  <input type="email" class="form-control" id="floatingInput" v-model="email_change">
                  <label for="floatingInput">Email</label>
                </div>
                <p class="text-danger">{{ this.response }}</p>
                <div class="row">
                  <button class="col-6 mx-auto btn btn-dark" @click="ChangePass">Enviar</button>
                </div>
              </div>
              <div v-else>
                <img src="@/assets/tick.gif" alt="Email enviada" class="img-fluid w-25" />
                <h3 class="text-success mb-3">¡Peticion procesada!</h3>
                <p>Te hemos enviado un correo electronico para poder cambiar tu contraseña</p>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  import { mapState } from 'vuex';
  import router from '@/router';

  export default {
    name: 'LoginPage',
    data: function() {
      return {
        userLogin: {
          email: "",
          password: ""
        },
        saludo: '',
        changing_pass: false,
        email_change: "",
        response: "",
        sended: false
      }
    },
    computed:{
      ...mapState(['isLoggedIn']),
      ...mapState(['token']),
      ...mapState(['refresh'])
  },
  components:{
    SpinnerComponent
  },
  mounted() {
    this.setSaludo();
  },
  methods:{
    async IniciarSesion(){
      var spinner = document.getElementById("spinner")
      var incorrect = document.getElementById("incorrect")
      spinner.classList.remove("d-none")
      
      const apiUrl = process.env.VUE_APP_API_URL+'/v1/api/partner/pair';
      axios.post(apiUrl, this.userLogin)
      .then(response => {
        if (response.data.user_admin){
          this.$store.dispatch('setTokenAdmin', response.data); 
          router.push({ name: "LoginAdmin" });
          return
        }

        this.$store.dispatch('setToken', response.data); 
        incorrect.classList.add("d-none")
        console.log(response)
        
        if (response.data.user_partner){
          router.push({ name: "PartnerSettings" });
        }else{
          router.push({ name: "PanelComandas" });
        }
      })
      .catch(error => {
        console.error(error);
        incorrect.classList.remove("d-none")
        spinner.classList.add("d-none")
      });
    },
    async ChangePass(){
      if (!this.email_change){
        this.response = "Introduce un correo correcto"
        return
      }

      var spinner = document.getElementById("spinner")
      spinner.classList.remove("d-none")
      
      const apiUrl = process.env.VUE_APP_API_URL+'/v1/api/partner/ChangePassword?email=' + this.email_change;
      axios.post(apiUrl)
      .then(response => {
        console.log(response)
        this.sended = true
        spinner.classList.add("d-none")
        setTimeout(() => {
          this.sended = false
          this.changing_pass = false
          this.email_change = ""
          this.response = ""
        }, 5000);
      })
      .catch(error => {
        console.error(error);
        this.response = "El email no corresponde a ningun cliente"
        spinner.classList.add("d-none")
      });
    },
    setSaludo() {
      const hora = new Date().getHours();
      console.log(hora + " Ahora son")
      if (hora >= 6 && hora < 13) {
        this.saludo = '¡Buenos días!';
      } else if (hora >= 13 && hora < 20) {
        this.saludo = '¡Buenas tardes!';
      } else {
        this.saludo = '¡Buenas noches!';
      }
    },
  }
}

</script>

<style scoped>
  .login-input{
    display: flex;
    height: 60px;
    padding: 20px;
    font-size: 1.1rem;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border: 2px solid #dbdbdb;
    border-radius: 6px;
    color: #0A2533;
  }

  .login-input input{
    box-shadow: 0px;
    width: 85%;
    border: 0px;
  }

  .login-input input:focus{
    border: 0px !important;
    outline: none;
  }

  .decorative-text {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .decorative-text::before,
  .decorative-text::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid orange;
    margin: 0 10px;
    height: 0;
  }

  .decorative-text::before {
    border-left: 50px solid transparent;
  }

  .decorative-text::after {
    border-right: 50px solid transparent;
  }

  .decorative-text a {
    font-size: 18px;
    font-weight: bold;
    color: #555555;
  }

  .login-image{
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .login-div{
    padding: 0px 250px 0px 250px;
    height: 100vh;
  }

  .page{
    overflow: hidden;
  }

  .modal {
    background: rgba(139, 139, 139, 0.5);
  }
  .modal-dialog {
    min-height: 90vh;
  }

  @media screen and (max-width: 1700px) {
    .login-div{
      padding: 0px 220px 0px 220px;
    }
  }

  @media screen and (max-width: 1500px) {
    .login-div{
      padding: 0px 180px 0px 180px;
    }
  }

  @media screen and (max-width: 1350px) {
    .login-div{
      padding: 0px 150px 0px 150px;
    }
  }

  @media screen and (max-width: 1200px) {
    .login-div{
      padding: 0px 100px 0px 100px;
    }
  }

  @media screen and (max-width: 576px) {
    .login-div{
      padding: 0px 50px 0px 50px;
    }
  }

  @media screen and (max-width: 500px) {
    .login-div{
      padding: 0px 20px 0px 20px;
    }
  }
</style>