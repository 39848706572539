<template>
    <div id="container-principal">
        <h1 class="text-center mt-5 fw-normal">Empleados</h1>
        <p class="text-center">Nº total de Empleados: {{ employees.length }}</p>
        <div class="d-flex justify-content-center mb-3">
            <div class="d-flex justify-content-between align-items-center col-xl-5 col-md-8">
                <div id="search-btn" class="w-100">
                    <i class="fa-solid fa-search"></i>
                    <input autocomplete="off" class="search-input" placeholder="Buscar" v-model="search">
                </div>
                <button class="btn btn-add px-5 ml-3" @click="showAddEmployeeModal">Añadir</button>
            </div>
        </div>

        <div v-if="filteredEmployees.length > 0" class="row d-flex align-items-center justify-content-center">
            <div class="col-11 col-md-6 col-lg-3 mb-3" v-for="employee in filteredEmployees" :key="employee.id">
                <div class="card">
                    <div class="card-body">
                        <h3 id="employee-name" class="text-truncate text-center">{{ employee.username }}</h3>
                    </div>
                    <div class="card-footer bg-white d-flex justify-content-center position-absolute w-100">
                        <div class="action-item d-flex flex-column align-items-center mr-5" @click="showEditModal(employee)">
                            <i class="fa-solid fa-pencil action-icon"></i>
                            <span style="color:#f47700">Editar</span>
                        </div>
                        <div class="action-item d-flex flex-column align-items-center" @click="showDeleteModal(employee)">
                            <i class="fa-regular fa-trash-can action-icon"></i>
                            <span style="color:#f47700">Eliminar</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <p v-else class="text-center">No hay empleados.</p>

        <EditEmployeeModalComponent
            :show-modal="showEdit"
            :employee="selectedEmployee"
            @close-modal="closeEditModal"
            @update-employee="updateEmployee"
        />
        <DeleteEmployeeModalComponent
            :show-modal="showDelete"
            :employee="selectedEmployee"
            @close-modal="closeDeleteModal"
            @confirm-delete="confirmDeleteEmployee"
        />
        <AddEmployeeModalComponent
            ref="AddEmployeeModal"
            :show-modal="showAdd"
            @close-modal="closeAddModal"
            @confirm-add="addEmployee"
        />
        <SpinnerComponent id="spinner"></SpinnerComponent>
    </div>
</template>

<script>
    import EditEmployeeModalComponent from '../components/EditEmployeeModalComponent.vue';
    import DeleteEmployeeModalComponent from '../components/DeleteEmployeeModalComponent.vue';
    import AddEmployeeModalComponent from '../components/AddEmployeeModalComponent.vue';

    import { mapState } from 'vuex';
    import axios from 'axios';
    import SpinnerComponent from '../components/SpinnerComponent.vue'
    import { v4 as uuidv4 } from 'uuid';

    export default {
        components: {
            EditEmployeeModalComponent,
            DeleteEmployeeModalComponent,
            AddEmployeeModalComponent,
            SpinnerComponent
        },
        data() {
            return {
                employees: [],
                filteredEmployees: [],
                search: '',
                selectedEmployee: null,
                showEdit: false,
                showDelete: false,
                showAdd: false
            };
        },
        computed: {
            ...mapState(['partner_token'])
        },
        watch: {
            search(newSearch) {
                this.filterEmployees(newSearch);
            },
            employees(){
                this.filterEmployees(this.search)
            }
        },
        mounted() {
            this.filteredEmployees = this.employees;
        },
        methods: {
            filterEmployees(searchTerm) {
                if (searchTerm) {
                    this.filteredEmployees = this.employees.filter(employee => 
                        employee.username.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                } else {
                    this.filteredEmployees = this.employees;
                }
            },
            showEditModal(employee) {
                this.selectedEmployee = { ...employee };
                this.showEdit = true;
            },
            closeEditModal() {
                this.showEdit = false;
                this.selectedEmployee = null;
            },
            showDeleteModal(employee) {
                this.selectedEmployee = { ...employee };
                this.showDelete = true;
            },
            closeDeleteModal() {
                this.showDelete = false;
                this.selectedEmployee = null;
            },
            showAddEmployeeModal() {
                this.showAdd = true;
            },
            closeAddModal() {
                this.showAdd = false;
            },
            async addEmployee(newEmployee) {
                let camarero = {
                    id: String(uuidv4()),
                    username: newEmployee.username,
                    email: newEmployee.email,
                    password: newEmployee.password
                }
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/camareroCreate';
                try {
                    const response = await axios.post(url, camarero, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getWaiters()
                    this.closeAddModal();
                    this.$refs.AddEmployeeModal.clean()
                } catch (error) {
                    document.getElementById("span-error").textContent = "El usuario o el email se repiten en alguno de los usuarios"
                    console.error('Error fetching category:', error);
                }
                
            },
            async updateEmployee(updatedEmployee) {
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/camareroUpdate';
                try {
                    const response = await axios.put(url, updatedEmployee, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getWaiters()
                    this.closeEditModal();
                } catch (error) {
                    console.error('Error updating category:', error);
                    document.getElementById("span-error").textContent = "El usuario o el email se repiten en alguno de los usuarios"
                }
            },
            async confirmDeleteEmployee(id) {
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/camareroDelete?waiter_id='+id;
                try {
                    const response = await axios.delete(url, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getWaiters()
                } catch (error) {
                    console.error('Error deleting plate:', error);
                }
                this.closeDeleteModal();
            },
            async getWaiters(){
                if (document.getElementById("spinner")){
                    document.getElementById("spinner").classList.remove("d-none")
                }
                const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/GetCamareros';
                try {
                    const response = await axios.get(url, {
                        headers: {
                        'Authorization': `Bearer ${token}`
                        }
                    });

                    // Maneja la respuesta
                    console.log(response.data);
                    this.employees = response.data;
                    this.employees = this.employees.sort((a, b) => {
                        return a.username.localeCompare(b.username);
                    });
                    if (document.getElementById("spinner")){
                        document.getElementById("spinner").classList.add("d-none")
                    }
                } catch (error) {
                    // Maneja el error
                    this.employees = []
                    if (document.getElementById("spinner")){
                        document.getElementById("spinner").classList.add("d-none")
                    }
                    console.error('Error fetching tables:', error);
                }
            }
        },
        created() {
            this.getWaiters()
        },
    };
</script>

<style scoped>
    .card {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 16px;
        background-color: #fff;
        position: relative;
        height: 160px;
    }

    #employee-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.3rem;
    }

    .action-icon {
        font-size: 2rem;
        color: #f47700;
        cursor: pointer;
    }

    .action-item {
        font-size: 1rem;
        text-align: center;
    }

    .btn.btn-add {
        background-color: #f47700;
        color: white;
        font-size: 1.25rem;
        border-radius: 8px;
        height: 40px;
        border: none;
        cursor: pointer;
    }

    .card-footer {
        border-top: none;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    #search-btn {
        display: flex;
        height: 35px;
        background: #fff;
        padding: 20px;
        font-size: 16px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border: 2px solid #dbdbdb;
        border-radius: 13px;
        color: #0A2533;
    }

    @media (max-width: 576px) {
        .action-icon {
            font-size: 1.8rem;
        }

        .action-item {
            font-size: 1.25rem;
        }

        #container-principal{
            width: 90%;
            margin: auto;
        }
    }

    @media (min-width: 578px){
        #container-principal{
            margin-left:22%; 
            width: 77%;
        }
    }
</style>