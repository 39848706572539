<template>
    <div v-if="showModal" class="modal fade show d-block z-3 d-flex align-items-center justify-content-center" tabindex="-1">
      <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
      <div class="modal-dialog z-2 m-4 d-flex align-items-center justify-content-center">
        <div class="modal-content p-2">
          <div class="modal-header d-flex align-items-center justify-content-center border-none">
            <h3 class="modal-title">Eliminar Plato</h3>
          </div>
          <div class="modal-body">
            <p class="text-center">¿Estás seguro que deseas eliminar el plato "{{ dish.name }}"?</p>
          </div>
          <div class="modal-footer d-flex justify-content-center border-none">
            <button type="button" class="btn text-white" style="background-color: #f47700;" @click="closeModal">Cancelar</button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">Eliminar</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        props: {
            showModal: Boolean,
            dish: Object,
        },
        methods: {
            closeModal() {
                this.$emit('close-modal');
            },
            confirmDelete() {
                this.$emit('confirm-delete', this.dish.id);
            },
        },
    };
</script>

<style scoped>
  .modal {
    background-color: rgba(139, 139, 139, 0.5);
  }

  .modal-dialog {
    height: 90vh; 
    min-width: 60%;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 90vh;
  }

  @media (min-width: 767px) {
    .modal-dialog {
      position: absolute;
      width: 80%;
      left: 28%;
      min-height: 90vh; 
      min-width: 60%; 
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }
</style>