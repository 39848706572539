<template>
    <div>
      <SpinnerComponent id="spinner"></SpinnerComponent>
      <div v-if="unauthorized == false"></div>
      <div v-else-if="unauthorized == 1">
        <div class="container-fluid d-flex align-items-center justify-content-center min-vh-100">
            <div class="text-center">
                <div class="row d-flex align-items-center justify-content-center">
                    <img src="./../assets/ServerError.png" id="imagen" alt="Server Error">
                </div>
                <p class="text-center fs-5 fw-semibold" style="color:#f47600">UPS algo fue mal...</p>
            </div>
        </div>
      </div>
      <div v-else-if="unauthorized == 2">
        <div class="container-fluid d-flex align-items-center justify-content-center min-vh-100">
            <div class="text-center">
                <div class="row d-flex align-items-center justify-content-center">
                    <img src="./../assets/GeolocationError.png" id="imagen" alt="Unauthorized Error">
                </div>
                <p class="text-center fs-5 fw-semibold">
                    Parece ser que no se encuetra en el restaurante en estos momentos <br/>
                    <span style="color:#f47600">Contacte con el personal</span>
                </p>
            </div>
        </div>
      </div>
      <div v-else-if="unauthorized == 3">
        <div class="container-fluid d-flex align-items-center justify-content-center min-vh-100">
            <div class="text-center">
                <div class="row d-flex align-items-center justify-content-center">
                    <img src="./../assets/NotActiveTable.png" id="imagen" alt="Unauthorized Error">
                </div>
                <p class="text-center fs-5 fw-semibold">
                    Espere hasta que se active su mesa <br/>
                    <span style="color:#f47600">Un momento por favor...</span>
                </p>
            </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  import axios from 'axios';
  import router from '@/router';

  export default {
    name: 'HomeA',
    data: function() {
          return {
            location: null,
            unauthorized: false,
            pollingInterval: null,
            mesaId: this.$route.params.mesa,
            required_location: null
        }
      },
    components: {
        SpinnerComponent
    },
    computed:{
        //...mapState(['isLoggedIn'])
    },
    methods: {
        async LoginQr(){
            if (document.getElementById("spinner") && document.getElementById("spinner").classList.contains("d-none")) {
                document.getElementById("spinner").classList.remove("d-none");
            }
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL+'/v1/api/app/restaurant_config?table_id='+this.mesaId);
                console.log(response)
                this.required_location = response.data.required_location
                
                if (this.required_location){
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(this.ContinueWithLocation, this.showError);
                    } else {
                        console.log("Geolocation is not supported by this browser.");
                        this.ContinueWithLocation(null)
                    }
                }else{
                    //Llamada sin location
                    this.ContinueWithLocation(null)
                }

            } catch (error) {
                // TODO: Redirigir a un 500
                document.getElementById("spinner").classList.add("d-none")
                this.unauthorized = 1
            }
        },
        async ContinueWithLocation(position) {
            const mesaId = this.$route.params.mesa;
            let url = process.env.VUE_APP_API_URL+'/v1/api/app/loginTable?table_id='+mesaId
            console.log(url)

            if(position){
                this.location = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                };
                url += "&longitude="+this.location.longitude+"&latitude="+ this.location.latitude
            }

            try {
                const response = await axios.post(url);
                // Puedes verificar específicamente el estado de la respuesta
                
                if (response.status === 200) {
                    this.unauthorized = false
                    console.log('Success:', response.data);
                    this.$store.dispatch('setTokenTable', response.data);
                    router.push({ name: "Home" });
                } else {
                    
                    console.error('Unexpected status code:', response);
                    if (response.data["error"] == "Invalid location"){
                        this.unauthorized = 2
                    }else if (response.data["error"] == "Table session not active"){
                        this.unauthorized = 3
                    }
                        
                    document.getElementById("spinner").classList.add("d-none")
                }
            } catch (error) {
                console.log("AQUIII")
                // Maneja los errores de red o los estados fuera del rango 2xx
                console.log(error.response.data.error)
                if (error.response.data.error == "Invalid location"){
                    this.unauthorized = 2
                    console.log("Invalid location")
                }else if (error.response.data.error == "Table session not active"){
                    console.log("table not active")
                    this.unauthorized = 3
                }else{
                    this.unauthorized = 1
                }
                
                document.getElementById("spinner").classList.add("d-none")
            }
    
        },
        startPolling() {
            this.LoginQr();
            this.pollingInterval = setInterval(this.LoginQr, 10000); // Cada 5 segundos
        },
        stopPolling() {
            clearInterval(this.pollingInterval);
        },
    },
    created() {
        this.startPolling();
    },
    beforeUnmount() {
        this.stopPolling();
    }
  }
</script>
  
<style scoped>
    #imagen {
        width: 80%;
        max-width: 400px;
        height: auto;
    }

    @media (max-width: 768px) {
        #imagen {
        width: 100%;
        }
    }

    @media (min-width: 768px) {
        p {
        font-size: 1.25rem;
        }
    }
</style>