<template>
  <div class="container-fluid p-4">
    <h1 class="text-center fw-normal">Mesas</h1>
    <div id="categories-container" class="d-flex justify-content-center my-3">
      <div id="categories-nav" class="btn-group" role="group">
        <button class="btn rounded-4 mx-1 categories-btn" :class="{ active: filter === 'todos' }" @click="filterTables('todos')">Todos</button>
        <button class="btn rounded-4 mx-1 categories-btn" :class="{ active: filter === 'ocupada' }" @click="filterTables('ocupada')">Ocupada</button>
        <button class="btn rounded-4 mx-1 categories-btn" :class="{ active: filter === 'libre' }" @click="filterTables('libre')">Libre</button>
        <button class="btn rounded-4 mx-1 categories-btn" :class="{ active: filter === 'cuenta' }" @click="filterTables('cuenta')">Cuenta</button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="row" style="width: 100vw !important;" v-if="this.filteredTables.length == 0">
        <img class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-11 mx-auto" src="../assets/not_plates_founds.png">
        <h5 class="text-center mt-1">No hay mesas en este estado</h5>
      </div>
      <div v-for="table in filteredTables" :key="table.id" class="col-12 col-sm-6 col-lg-4 mb-4">
        <div class="card shadow-sm d-flex flex-column justify-content-between h-100">
          <span v-if="table.state === 'cuenta' || table.state === 'llamada'" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style="font-size: 1rem;">
            1
          </span>
          <div class="d-flex flex-column justify-content-between pt-3">
            <p class="card-title text-center mb-1 fw-bold fs-4">{{ table.number }}</p>
            <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
              <div v-if="table.state === 'libre'" class="d-flex flex-column justify-content-center align-items-center py-2">
                <div class="d-flex justify-content-center align-items-center mb-2">
                  <p class="fw-bold mb-0 me-2 text-end" style="width: 120px;">Adultos:</p>
                  <div class="d-flex align-items-center">
                    <i class="fa-solid fa-minus botonCantidad py-1 me-2 px-1" @click="updateDinners(table, -1, true)"></i>
                    <span style="color: #19344F" :id="`${table.id}_adults`" class="fw-bold">0</span>
                    <i class="fa-solid fa-plus botonCantidad py-1 ms-2 px-1" @click="updateDinners(table, 1, true)"></i>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <p class="fw-bold mb-0 me-2 text-end" style="width: 120px;">Niños:</p>
                  <div class="d-flex align-items-center">
                    <i class="fa-solid fa-minus botonCantidad py-1 me-2 px-1" @click="updateDinners(table, -1, false)"></i>
                    <span style="color: #19344F" :id="`${table.id}_children`" class="fw-bold">0</span>
                    <i class="fa-solid fa-plus botonCantidad py-1 ms-2 px-1" @click="updateDinners(table, 1, false)"></i>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-danger" style="font-size: 0.9rem;">Capacidad: {{ table.max_diners }} comensales</p>
              </div>
              <div v-else class="text-center mt-3 mb-1">
                <p class="fw-bold">Comensales: {{ table.actual_diners }}</p>
              </div>
            </div>
            <div class="text-center">
              <strong class="fs-4" :class="table.state === 'libre' ? 'text-success' : table.state === 'ocupada' ? 'text-danger' : table.state === 'cuenta' ? 'text-warning': 'text-info'">{{ table.state.toUpperCase() }}</strong>
            </div>
          </div>
          <div v-if="table.state != 'libre'">
            <p v-if="table.mode_name" class="text-center fw-semibold mb-0">Modo: {{ table.mode_name }}</p>
            <p v-else class="text-center fw-semibold mb-0">Modo: Normal</p>
          </div>
          <div class="card-footer bg-transparent border-0 pb-3 text-center mt-0 d-flex justify-content-center">
            <button v-if="table.state === 'libre'" class="btn btn-dark btn-sm w-50 rounded-2" @click="showActivateModal(table.id)">ACTIVAR</button>
            <button v-if="table.state === 'ocupada'" class="btn btn-dark btn-sm w-50 rounded-2" @click="unlockTable(table.id)">LIBERAR</button>
            <button v-if="table.state === 'cuenta'" class="btn btn-dark btn-sm rounded-2" @click="attendTable(table.id)">ATENDIDA</button>
            <button v-if="table.state === 'cuenta'" class="btn btn-dark btn-sm rounded-2 ms-2" @click="payedTable(table.id)">PAGADO</button>
            <button v-if="table.state === 'llamada'" class="btn btn-dark btn-sm w-50 rounded-2" @click="attendTable(table.id)">ATENDIDA</button>
            <!--<div v-if="table.state === 'cuenta'" class="d-flex justify-content-between px-3">
              <button class="btn btn-dark btn-sm w-50 mr-5 rounded-2" @click="attendTable(table.id)">ATENDIDA</button>
              <button class="btn btn-dark btn-sm w-50 rounded-2" @click="unlockTable(table.id)">LIBERAR</button>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <ActivateTableModalComponent
      :show-modal="showModal"
      :activate-table-id="activateTableId"
      :modes="modes"
      @close-modal="closeModal"
      @confirm-activation="activateTable"
    />
    <SpinnerComponent id="spinner" style="z-index: 1000;"></SpinnerComponent>
  </div>
</template>

<script>
  import ActivateTableModalComponent from '@/components/ActivateTableModalComponent.vue';
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  import axios from 'axios';
  import { mapState } from 'vuex';
  
  export default {
    components: {
      ActivateTableModalComponent,
      SpinnerComponent
    },
    data() {
      return {
        filter: "todos",
        showModal: false,
        activateTableId: null,
        tables: [],
        modes: [],
        first_charge: true
      };
    },
    computed: {
      filteredTables() {
        const tablesCopy = [...this.tables]; // Crear una copia del array para no mutar el original
        if (this.filter === "todos") {
          return tablesCopy // Ordenar la copia
        }
        return tablesCopy
          .filter((table) => table.state === this.filter) // Filtrar la copia
      },
      ...mapState(['partner_token'])
    },
    methods: {
      filterTables(filter) {
        this.filter = filter;
      },
      async unlockTable(id) {
        const table = this.tables.find((table) => table.id === id);
        if (table) {
          document.getElementById("spinner").classList.remove("d-none")
          let url = process.env.VUE_APP_API_URL+'/v1/api/app/desactiveTable?table_id='+id          
          try {
            const response = await axios.post(url, {}, {
              headers: {
                'Authorization': `Bearer ${this.partner_token}`
              }
            });
            console.log(response.data);
            table.state = 'libre'
            document.getElementById("spinner").classList.add("d-none")
          } catch (error) {
            console.error('Error fetching shopping cart:', error);
            document.getElementById("spinner").classList.add("d-none")
          }
        }
      },
      showActivateModal(id) {
        let adults_int = parseInt(document.getElementById(id+"_adults").innerHTML, 10);
        console.log(adults_int)
        if (adults_int <= 0){
          document.getElementById(id+"_adults").classList.add('shake');
          setTimeout(() => {
            document.getElementById(id+"_adults").classList.remove('shake');
          }, 500);
          return
        }
        if (this.modes.length == 0){
          this.activateTable({ id:id, mode:null })
          return
        }
        this.activateTableId = id;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
        this.activateTableId = null;
      },
      async activateTable({ id, mode }) {
        let adults_int = parseInt(document.getElementById(id+"_adults").innerHTML, 10);
        let children_int = parseInt(document.getElementById(id+"_children").innerHTML, 10);

        document.getElementById("spinner").classList.remove("d-none")

        const table = this.tables.find((table) => table.id === id);
        
        let url = process.env.VUE_APP_API_URL+'/v1/api/app/activeTable?table_id='+id
        if (mode) url += "&mode_id="+mode
        url += "&adults="+adults_int+"&children="+children_int;
        
        try {
          const response = await axios.post(url, {}, {
            headers: {
              'Authorization': `Bearer ${this.partner_token}`
            }
          });
          console.log(response.data);
          table.state = 'ocupada'
          this.first_charge = true
          this.fetchMesas()
          document.getElementById("spinner").classList.add("d-none")
        } catch (error) {
          document.getElementById("spinner").classList.add("d-none")
          console.error('Error fetching shopping cart:', error);
        }
        this.activateTableId = null;
        this.showModal = false;
      },
      async attendTable(id) {
        document.getElementById("spinner").classList.remove("d-none")
        const table = this.tables.find((table) => table.id === id);
        if (table) {
          let url = process.env.VUE_APP_API_URL+'/v1/api/app/attendTable?table_id='+id          
          try {
            const response = await axios.post(url, {}, {
              headers: {
                'Authorization': `Bearer ${this.partner_token}`
              }
            });
            console.log(response.data);
            table.state = 'ocupada'
            document.getElementById("spinner").classList.add("d-none")
          } catch (error) {
            document.getElementById("spinner").classList.add("d-none")
            console.error('Error fetching shopping cart:', error);
          }
        }
      },
      async payedTable(id) {
        document.getElementById("spinner").classList.remove("d-none")
        const table = this.tables.find((table) => table.id === id);
        if (table) {
          let url = process.env.VUE_APP_API_URL+'/v1/api/app/payedTable?table_id='+id          
          try {
            const response = await axios.post(url, {}, {
              headers: {
                'Authorization': `Bearer ${this.partner_token}`
              }
            });
            console.log(response.data);
            table.state = 'ocupada'
            document.getElementById("spinner").classList.add("d-none")
          } catch (error) {
            console.error('Error fetching shopping cart:', error);
            document.getElementById("spinner").classList.add("d-none")
          }
        }
      },
      updateDinners(table, incremento, is_adults) {
        let string = "adults"
        //let string2 = "children"
        if (!is_adults){
          string = "children"
          //string2 = "adults"
        }
        let selected = document.getElementById(table.id+"_"+string)
        let selected_int = parseInt(selected.innerHTML, 10);

        //let not_selected = document.getElementById(table.id+"_"+string2)
        //let not_selected_int = parseInt(not_selected.innerHTML, 10);

        selected_int += incremento

        if (selected_int < 0) selected_int = 0
        //if ((selected_int + not_selected_int) > table.max_diners) selected_int -= incremento
        selected.innerHTML = selected_int
      },
      async fetchMesas() {
        if (this.first_charge && document.getElementById("spinner")){
            document.getElementById("spinner").classList.remove("d-none")
        }
        const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
        const url = process.env.VUE_APP_API_URL+'/v1/api/app/PanelTables';
        try {
          const response = await axios.get(url, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          // Maneja la respuesta
          console.log(response.data);
          this.tables = response.data.tables
          this.modes = response.data.modes
          console.log(this.tables)
          this.tables.forEach(table => {
              table.actual_diners = Number(table.actual_diners) + Number(table.actual_children)
              if(table.mode != null){
                table.mode_name = table.mode.name
              }
              if(table.start_time == null){
                table.state = 'libre'
              }else if (table.ordered_bill){
                table.state = 'cuenta'
              }else if(table.waiter_call){
                table.state = 'llamada'
              }else{
                table.state = 'ocupada'
              }
          })

          if(this.first_charge){
            if (document.getElementById("spinner")){
                document.getElementById("spinner").classList.add("d-none")
            }
            this.first_charge=false
          }
          this.tables = this.tables.sort((a, b) => {
              return a.number.localeCompare(b.number);
          });
        } catch (error) {
          // Maneja el error
          this.tables = []
          if (document.getElementById("spinner")){
              document.getElementById("spinner").classList.add("d-none")
          }
          console.error('Error fetching tables:', error);
        }
      },
      startPolling() {
          this.fetchMesas();
          this.pollingInterval = setInterval(this.fetchMesas, 15000); // Cada 5 segundos
      },
      stopPolling() {
        clearInterval(this.pollingInterval);
      },
    },
    created() {
      this.startPolling();
    },
    beforeUnmount() {
      this.stopPolling();
    }
  };
</script>

<style scoped>
  .categories-btn{
    background-color: #f1f5f5;
  }

  .card-title {
    font-size: 1.25rem;
    word-wrap: break-word;
  }

  .card {
    height: 100%;
  }

  .card-footer {
    margin-top: auto;
  }

  .btn-group .btn.active {
    background-color: #f47700;
    color: white;
  }

  .btn {
    white-space: nowrap;
  }

  .custom-btn:focus, 
  .custom-btn:active, 
  .custom-btn:hover {
    background-color: transparent;
    border-color: #f47700;
    color: #f47700;
    box-shadow: none;
  }

  @media (max-width: 576px) {
    .btn {
      font-size: 0.9rem;
      padding: 0.25rem 0.5rem;
    }

    .card-title {
      font-size: 1rem;
    }
  }
</style>