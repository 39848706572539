<template>
  <div v-if="isOpen" class="modal fade show d-block d-flex align-items-center justify-content-center" tabindex="-1">
    <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
    <div class="modal-dialog z-2 m-4">
      <div class="modal-content p-2 custom-scroll">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="modal-title flex-grow-1 text-center">Editar Modo</h2>
          <button type="button" class="btn-close" @click="closeModal()"></button>
        </div>
        <form class="mt-4 p-4">
          <div class="form-group">
            <label for="name" class="mb-2">Nombre:<span class="text-danger">*</span></label>
            <input type="text" class="form-control w-md-50 shadow-sm rounded-3" id="name" v-model="modo.name" maxlength="50">
          </div>
          <div class="form-group mt-5">
            <label class="mr-2 mb-2">Descuento aplicado a toda la carta (%):</label>
            <div class="input-group custom-input-group shadow-sm">
              <input type="number" class="form-control custom-input" v-model="modo.discount" min="0" max="100">
              <button class="btn custom-btn" type="button" @click="modo.discount > 0 && modo.discount--">-</button>
              <button class="btn custom-btn" type="button" @click="modo.discount < 100 && modo.discount++">+</button>
            </div>
          </div>
          <div class="form-group mt-5">
            <label>Categorías a excluir:</label>
            <v-col class="col-md-6 col-12">
              <v-combobox 
                :items="allCategories" 
                label="Selecciona las categorías que quiera excluir" 
                chips 
                multiple 
                v-model="modo.excludedCategories">
              </v-combobox>
            </v-col>
          </div>
          <div class="form-group mt-3">
            <label>Nº max platos p.p./pedido:</label>
            <div class="input-group custom-input-group shadow-sm mt-3">
              <input type="number" class="form-control custom-input" v-model="modo.platesMax" min="0" max="100">
              <button class="btn custom-btn" type="button" @click="modo.platesMax > 1 && modo.platesMax--">-</button>
              <button class="btn custom-btn" type="button" @click="modo.platesMax++">+</button>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col">
              <label class="fw-bold">Precio inicial por comensal</label>
              <button type="button" @click="addPriceBlock"><img src="../assets/icons8-más-50.png" class="btn mb-2 w-50 p-0 m-0"/></button>
            </div>
          </div>
          <div v-for="(priceBlock, index) in modo.priceBlocks" :key="index" class="border w-md-75 p-4 mb-3 position-relative">
            <button v-if="modo.priceBlocks.length > 1" @click="removePriceBlock(index)" type="button" class="btn btn-danger position-absolute" style="top: 10px; right: 10px;">&times;</button>
            <div class="form-group mt-3">
              <label>Días de la semana:<span class="text-danger">*</span></label>
              <div class="d-flex justify-content-between">
                <label v-for="(day, i) in days" :key="i" class="d-inline-block">
                  <input type="checkbox" :value="day" :id="'day-' + i + '-' + index" v-model="priceBlock.selectedDays">{{ day }}
                </label>
              </div>
            </div>
            <div class="form-group mt-4">
              <label>Horario de aplicación:<span class="text-danger">*</span></label>
              <div id="time-container">
                <div class="d-flex flex-column align-items-start d-sm-none">
                  <span class="mr-2">de</span>
                  <input type="time" class="form-control mb-2 time-input" v-model="priceBlock.horaInicio"/>
                  <span class="mr-2">a</span>
                  <input type="time" class="form-control time-input" v-model="priceBlock.horaFin"/>
                </div>
                <div class="d-none d-sm-flex align-items-center flex-wrap mb-2">
                  <span class="mr-2">de</span>
                  <input type="time" class="form-control mx-2 text-center time-input" v-model="priceBlock.horaInicio"/>
                  <span class="mr-2">a</span>
                  <input type="time" class="form-control mx-2 text-center time-input" v-model="priceBlock.horaFin"/>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mt-4 mb-2 ml-2 w-md-50">
              <div class="col-6 mr-3">
                <div class="form-group position-relative">
                  <input type="number" class="form-control text-start border-none border-bottom" placeholder="€" v-model="priceBlock.adultPrice" />
                  <div class="form-text">€/Adulto<span class="text-danger">*</span></div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <input type="number" class="form-control text-start border-none border-bottom" placeholder="€" v-model="priceBlock.childPrice" />
                  <div class="form-text">€/Niño<span class="text-danger">*</span></div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
          <div class="d-flex justify-content-center">
            <button type="button" class="btn mt-3 text-white fw-bold" style="background-color: #091E33;" @click="updateModo">Guardar Cambios</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapState } from 'vuex';

  export default {
    props: {  isOpen: { type: Boolean, required: true }, modoData: Object },
    data() {
      return {
        modo: { ...this.modoData },
        allCategories: [],
        days: ["L", "M", "X", "J", "V", "S", "D"],
        errorMessage: ''
      };
    },
    watch: {
      isOpen(newVal) {
        if (newVal) {
          this.loadData();
        }
      },
    },
    methods: {
      async loadData() {
        await this.fetchCategories();
        this.modo = await this.getModoData();
      },
      async getModoData() {
        if (!this.modoData || !this.modoData.priceBlocks) {
          return {
            name: this.modoData?.name || '',
            discount: this.modoData?.discount || 0,
            excludedCategories: this.modoData?.excludedCategories || [],
            platesMax: this.modoData?.platesMax || 0,
            priceBlocks: [{ selectedDays: [], horaInicio: '', horaFin: '', adultPrice: '', childPrice: '' }],
          };
        }
        await this.fetchCategories();
        return { ...this.modoData };
      },
      async fetchCategories(){
        const url = process.env.VUE_APP_API_URL+'/v1/api/app/GetCategories';
        try {
          const response = await axios.get(url, {
            headers: {
              'Authorization': `Bearer ${this.partner_token}`,
            }
          });
          this.allCategories = response.data.map(cat => cat.name);
        } catch (error) {
          console.error('Error loading categories', error);
        }
      },
      closeModal() {
        this.$emit('close');
        Object.assign(this.$data, this.$options.data());
      },
      addPriceBlock() {
        this.modo.priceBlocks.push({
          selectedDays: [],
          horaInicio: '',
          horaFin: '',
          adultPrice: '',
          childPrice: ''
        });
      },
      removePriceBlock(index) {
        this.modo.priceBlocks.splice(index, 1);
      },
      updateModo() {
        if (!this.modo.name) {
          this.errorMessage = 'El nombre del modo es obligatorio.';
          return;
        }
        const hasInvalidBlock = this.modo.priceBlocks.some(block => {
          const adultPrice = parseFloat(block.adultPrice), childPrice = parseFloat(block.childPrice);
          if (!block.horaInicio || !block.horaFin || isNaN(adultPrice) || adultPrice <= 0 || isNaN(childPrice) || childPrice <= 0) {
            this.errorMessage = 'Datos inválidos: verifica las horas y precios.';
            return true;
          }
          return false;
        });
        
        if (hasInvalidBlock) return;

        this.$emit('actualizar', this.modo);
        this.errorMessage = "";
      }
    },
    async mounted() {
      this.modo = await this.getModoData();  
    },
    computed: {
      ...mapState(['partner_token'])
    },
  };
</script>

<style scoped>
  .modal { 
    background-color: rgba(139, 139, 139, 0.5); 
  }

  .modal-dialog {
    height: 90vh; 
    min-width: 60%;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 90vh;
  }

  @media (min-width: 767px) {
    .modal-dialog {
      position: absolute;
      width: 80%;
      left: 28%;
      min-height: 90vh; 
      min-width: 60%; 
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .custom-input-group { 
    display: flex; 
    align-items: center; 
    border: 1px solid #ddd; 
    border-radius: 50px;
    width: fit-content; 
  }

  .custom-input { 
    text-align: center; 
    border: none; 
    width: 60px; 
    padding: 5px; 
    font-size: 16px; 
    outline: none; 
  }

  .custom-btn { 
    background-color: white; 
    border: none; 
    padding: 5px 10px; 
    font-size: 18px;
    cursor: pointer; 
  }

  .custom-btn:hover { 
    background-color: #f0f0f0; 
  }

  input[type="checkbox"] { 
    appearance: none; 
    width: 15px; 
    height: 15px; 
    border-radius: 50%;
    margin-right: 10px; 
    border: 2px solid black; 
    cursor: pointer; 
  }

  input[type="checkbox"]:checked { 
    background-color: #f47600; 
    border-color: #f47600; 
  }

  #time-container {
    flex-direction: row;
  }

  .time-input {
    margin-bottom: 0;
    width: auto;
  }

  #time-container span {
    margin-bottom: 0;
  }

  .custom-scroll {
    padding-top: 10px;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 7px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }
</style>