<template>
  <div>
    <div v-if="visible" class="modal fade show d-block z-3" tabindex="-1" style="background-color: rgba(139, 139, 139, 0.5);" aria-modal="true" role="dialog">
      <div @click="$emit('close')" class="h-100 w-100 position-fixed z-1"></div>
      <div class="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center pb-5 z-2">
        <div class="modal-content rounded-4" style="width: 90%; max-height: 85vh;">
          <div class="modal-header flex-column align-items-center position-relative">
            <h3 class="modal-title">Mesa {{ order.table_number }}</h3>
            <span>{{ formatTime(order.date) }}</span>
            <div>
              <span v-if="dynamic_state === 'PENDIENTE'" class="badge bg-danger">Pendiente</span>
              <span v-else-if="dynamic_state === 'EN CURSO'" class="badge bg-warning">En curso</span>
              <span v-else-if="dynamic_state === 'ENTREGADO'" class="badge bg-success">Entregado</span>
              <span v-else-if="dynamic_state === 'PAGADO'" class="badge bg-primary">Pagado</span>
              <span v-else-if="dynamic_state === 'CANCELADO'" class="badge bg-grey">Cancelado</span>
            </div>
            <button type="button" class="position-absolute top-0 end-0 my-2 mx-3" style="color: #F47700;" aria-label="Close" @click="$emit('close')">
              <i class="fa-solid fa-xmark" style="font-size:25px"></i>
            </button>
          </div>
          <div class="modal-body" style="overflow-y: auto;">
            <div>
              <div v-if="pendingOrInProgressPlates.length==0">
                <div class="d-flex justify-content-center">
                  <img class="w-25" src="./../assets/full_order.png">
                </div>
                <p class="text-center">No hay platos pendientes</p>
              </div>
              <div v-for="plate in pendingOrInProgressPlates" :key="plate.id" class="card border-none shadow mb-3 rounded-4">
                <div class="row g-0 h-100">
                  <div class="col-4 d-flex align-items-center justify-content-center p-0">
                    <img :src="plate.photo ? plate.photo : noPhoto" :alt="plate.name" class="img-fluid d-block bg-light rounded-start img-fixed"/>
                  </div>
                  <div class="col-8 d-flex flex-column p-0">
                    <div v-if="plate.state === 'PENDIENTE'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="text-danger fs-6">Pendiente</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2">
                          <button class="btn bg-grey w-100 rounded-3" @click="updatePlateState(plate.shopping_cart_id, 'CANCELADO')">
                            Cancelar
                          </button>
                        </div>
                        <div class="col-lg-6">
                          <button class="btn text-white w-100 rounded-3" style="background-color: #F47700;" @click="updatePlateState(plate.shopping_cart_id, 'EN CURSO')">En curso</button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="plate.state === 'EN CURSO'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6" style="color: #F47700;">En curso</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2">
                          <button class="btn btn-danger w-100 rounded-3" @click="updatePlateState(plate.shopping_cart_id, 'PENDIENTE')">Pendiente</button>
                        </div>
                        <div class="col-lg-6">
                          <button class="btn btn-success w-100 rounded-3"  @click="updatePlateState(plate.shopping_cart_id, 'ENTREGADO')">Entregado</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4 d-flex align-items-center">
                <hr class="flex-grow-1" style="border-color: #F47700; border-width: 3px;" />
                <h5 class="mx-3 mt-2">Finalizados</h5>
                <hr class="flex-grow-1" style="border-color: #F47700; border-width: 3px;" />
              </div>
              <div v-if="finishedPlates.length==0">
                <div class="d-flex justify-content-center">
                  <img class="w-25" src="./../assets/empty_order.png">
                </div>
                <p class="text-center">No hay platos finalizados</p>
              </div>
              <div v-for="plate in finishedPlates" :key="plate.id" class="card border-none shadow mb-3 rounded-4">
                <div class="row g-0 h-100">
                  <div class="col-4 d-flex align-items-center justify-content-center p-0">
                    <img :src="plate.photo ? plate.photo : noPhoto" :alt="plate.name" class="img-fluid d-block bg-light rounded-start img-fixed"/>
                  </div>
                  <div class="col-8 d-flex flex-column p-0">
                    <div v-if="plate.state === 'ENTREGADO'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6 text-success">Entregado</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2">
                          <button class="btn text-white w-100 rounded-3" style="background-color: #F47700;" @click="updatePlateState(plate.shopping_cart_id, 'EN CURSO')">En curso</button>
                        </div>
                        <div class="col-lg-6">
                          <button class="btn btn-primary w-100 rounded-3"  @click="updatePlateState(plate.shopping_cart_id, 'PAGADO')">Pagado</button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="plate.state === 'CANCELADO'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6 text-grey">Cancelado</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2">
                          <button class="btn btn-danger w-100 rounded-3" @click="updatePlateState(plate.shopping_cart_id, 'PENDIENTE')">Pendiente</button>
                        </div>
                        <div class="col-lg-6">
                          <button class="btn btn-primary w-100 rounded-3"  @click="updatePlateState(plate.shopping_cart_id, 'PAGADO')">Pagado</button>
                        </div>
                      </div>
                    </div>
                    <div v-else class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6 text-primary">Pagado</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2">
                          <button class="btn btn-success w-100 rounded-3" @click="updatePlateState(plate.shopping_cart_id, 'ENTREGADO')">Entregado</button>
                        </div>
                        <div class="col-lg-6">
                          <button class="btn bg-grey w-100 rounded-3"  @click="updatePlateState(plate.shopping_cart_id, 'CANCELADO')">Cancelar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import noPhoto from './../assets/no_plate.png';

  export default {
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      order: {
        type: Object,
        required: true
      }
    },
    data() {
    return {
      noPhoto
    };
  },
    computed: {
      pendingOrInProgressPlates() {
        return this.order.plates.filter(plate => plate.state === 'PENDIENTE' || plate.state === 'EN CURSO');
      },
      finishedPlates() {
        return this.order.plates.filter(plate => plate.state === 'ENTREGADO' || plate.state === 'CANCELADO' || plate.state === 'PAGADO');
      },
      dynamic_state(){
        let min_state = 100
        let states = ["PENDIENTE", "EN CURSO", "ENTREGADO", "CANCELADO", "PAGADO"]
        this.order.plates.forEach( plate => {
          if (states.indexOf(plate.state) < min_state){
            min_state = states.indexOf(plate.state)
          }
        })
        return states[min_state]
      },
      ...mapState(['partner_token'])
    },
    methods: {
      formatTime(dateTime) {
        const parts = dateTime.split(' ');
        const timePart = parts[1];
        return timePart + 'h';
      },
      async updatePlateState(plateId, newState) {
        const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
        console.log(token)
        const url = process.env.VUE_APP_API_URL+'/v1/api/app/ChangeStatePlate?shopping_plate_id='+ plateId +'&state=' + newState;
        try {
          const response = await axios.put(url, {},{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          console.log(response.data);
          const plate = this.order.plates.find(p => p.shopping_cart_id === plateId);
          if (plate) {
            plate.state = newState;
          }
          this.$emit('fetchOrders');
        } catch (error) {
          // Maneja el error
          console.error('Error changing state:', error);
        }
      }
    }
  };
</script>

<style scoped>
  .img-fixed {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
</style>