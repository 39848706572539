<template>
    <div v-if="showModal" class="modal fade show d-block" tabindex="-1">
      <div @click="closeModal" class="h-100 w-100 position-fixed z-1"></div>
      <div class="modal-dialog modal-dialog-centered z-2">
          <div class="modal-content">
              <div class="modal-header d-flex align-items-center justify-content-center border-none">
                  <h3 class="modal-title">Eliminar Modo</h3>
              </div>
              <div class="modal-body">
                  <p class="text-center">¿Estás seguro que deseas eliminar el modo "{{ mode.name }}"?</p>
              </div>
              <div class="modal-footer d-flex justify-content-center border-none">
                  <button type="button" class="btn text-white" style="background-color: #f47700;" @click="closeModal">Cancelar</button>
                  <button type="button" class="btn btn-danger" @click="confirmDelete">Eliminar</button>
              </div>
          </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    props: {
      showModal: Boolean,
      mode: Object,
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      confirmDelete() {
        this.$emit('confirm-delete', this.mode.id);
      },
    },
  };
</script>
  
<style scoped>
  .modal {
    background-color: rgba(139, 139, 139, 0.5);
  }
</style>  