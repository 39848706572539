<template>
  <div>
    <div>
      <!-- Menú lateral -->
      <div v-if="$route.meta.partnerMenu" class="z-1">
        <!-- Botón para mostrar el menú -->
        <button @click="toggleSidebar" class="btn d-md-none m-1 z-1" style="color: #f47700; font-size:30px; position: fixed">
          <i :class="isSidebarVisible ? '' : 'fa-solid fa-bars'"></i>
        </button>
        <div class="powered d-md-block p-3 z-1" style="top:0; right: 0;">
          <span class="me-2">Powered by</span>
          <img style="width: auto; height: 14px;" src="../src/assets/logo_Negro.png"/> 
        </div>
        <div class="w-100 z-2 d-md-none" :class="{ 'd-none': !isSidebarVisible }" style="width:100vh; height: 100%; position: absolute; background: rgba(139, 139, 139, 0.5); top:0px" @click="toggleSidebar"></div>
        <div id="left-menu" ref="menu" :class="{ 'header_partner': true, 'd-none': !isSidebarVisible, 'd-block': isSidebarVisible, 'd-flex': true, 'flex-column': true, 'justify-content-start': true, 'vh-100': true }" style="background-color: #091E33; z-index: 3; position: relative;">
          <div class="d-flex align-items-center justify-content-center" style="position: absolute; top: 0; width: 100%; height: 60px;">
            <router-link to="/PanelComandas" target="_blank" class="text-warning text-decoration-none text-center w-100" style="font-size: 1rem; padding: 10px 0;">
              <i class="fa-solid fa-arrow-left me-1"></i>
              Modo camarero
            </router-link>
          </div>
          <div class="w-100 d-flex justify-content-center align-items-center mt-5 mb-2" style="margin-top: 60px;">
            <img src="../src/assets/EWU_Blanco.png" class="col-sm-8 col-12 px-1 logoPartner">
          </div>
          <p class="mt-5 text-center text-white mb-1 fw-semibold" style="font-size: 1.4rem;">Modo Admin</p>
          <p class="text-center" style="font-size: 0.8rem;">
            <router-link to="ChangePass" target="_blank" class="text-decoration-none text-warning">Cambiar contraseña</router-link>
          </p>
          <ul class="mt-3 list-group py-3 text-md-start text-center" style="font-size: 1.2rem;">
            <li class="mb-3 rounded-1 p-2" :class="{ selected: this.$route.path === '/PartnerSettings' }">
              <router-link to="/PartnerSettings" class="no-link-style">
                <i class="fa-solid fa-gear me-md-3"></i>
                Ajustes
              </router-link>
            </li>
            <li class="mb-3 rounded-1 p-2" :class="{ selected: this.$route.path === '/GestionCarta' }">
              <router-link to="/GestionCarta" class="no-link-style">
                <i class="fa-solid fa-utensils me-md-3"></i>
                Carta
              </router-link>
            </li>
            <li class="mb-3 rounded-1 p-2" :class="{ selected: this.$route.path === '/GestionMesas' }">
              <router-link to="/GestionMesas" class="no-link-style">
                <i class="fa-solid fa-chair me-md-3"></i>
                Mesas
              </router-link>
            </li>
            <li class="mb-3 rounded-1 p-2" :class="{ selected: this.$route.path === '/GestionCamareros' }">
              <router-link to="/GestionCamareros" class="no-link-style">
                <i class="fa-solid fa-users me-md-3"></i>
                Camareros
              </router-link>
            </li>
            <li class="mb-3 rounded-1 p-2" :class="{ selected: this.$route.path === '/DashboardPartner' }">
              <router-link to="/DashboardPartner" class="no-link-style">
                <i class="fa-solid fa-chart-pie me-md-3"></i>
                Dashboard
              </router-link>
            </li>
            <li class="mb-3 rounded-1 p-2" :class="{ selected: this.$route.path === '/MandarIncidencias' }">
              <router-link to="/MandarIncidencias" class="no-link-style">
                <i class="fa-solid fa-message me-md-3"></i>
                Incidencias
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--FIN MENU LATERAL DEL PARTNER-->
  <!--PAGE DEL PARTNER-->
  <router-view class="pagePartner" v-if="$route.meta.partnerMenu" @ReviseSession="ReviseSession"/>
  <!--PAGE DEL USER Y CAMARERO-->
  <router-view class="page" id="pagina" :max_plates="max_plates" @ReviseSession="ReviseSession" v-else-if="!$route.meta.fullpage"/>
  <router-view class="page" style="height: 100vh" :max_plates="max_plates" @ReviseSession="ReviseSession" v-else/>
  <!--MENU DEL USER-->
  <footer v-if="$route.meta.showFooter">
    <div class="row m-0 pt-3">
      <router-link to="/Home" class="col-3 text-decoration-none">
          <i class="fa-solid fa-utensils fs-3 w-100 text-center text-nav"></i>
          <p class="button-text text-center text-nav">Carta</p>
      </router-link>
      <router-link to="/Carrito" class="col-3 text-decoration-none pe-4 carrito" >
          <span class="amount-carrito px-2">{{carrito}}</span>
          <i class="fa-solid fa-basket-shopping fs-3 w-100 text-center text-nav"></i>
          <p class="button-text text-center mb-0 text-nav">
            Carrito
          </p>
      </router-link>
      <router-link to="/MisPedidos" class="col-3 text-decoration-none ps-4">
          <i class="fas fa-rectangle-list fs-3 w-100 text-center text-nav"></i>
          <p class="button-text text-center mb-0 text-nav">Pedidos</p>
      </router-link>
      <router-link to="/Cuenta" class="col-3 text-decoration-none">
          <i class="fas fa-receipt fs-3 w-100 text-center text-nav"></i>
          <p class="button-text text-center mb-0 text-nav">Cuenta</p>
      </router-link>
    </div>
  </footer>
  <div class="phone-icon d-flex align-items-center shadow" v-if="$route.meta.showFooter" @click="OpenCallWaiter()">
    <i class="fas fa-phone fs-2 w-100 text-center text-nav" style="transform: rotate(-270deg)"></i>
  </div>
  <svg class="footer-rounded" viewBox="0 0 100 20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" v-if="$route.meta.showFooter">
      <path d="M0 0H42Q50 9 59 -1H100V100H0Z" fill="white" />
  </svg>
  <div class="powered" v-if="$route.meta.showFooter">
    <span class="me-2">Powered by</span>
    <img style="width: auto; height: 14px;" src="../src/assets/logo_Negro.png"/> 
  </div>
  <!--FIN MENU DEL USER-->
  <!--INICIO MENU DEL CAMARERO-->
  <footer v-if="$route.meta.showFooterWaiter" class="bg-white shadow">
    <div class="row m-0 pt-3">
      <router-link to="/PanelComandas" class="col-4 text-decoration-none">
          <i class="fa-solid fa-list fs-3 w-100 text-center text-nav"></i>
          <p class="button-text text-center text-nav">Comandas</p>
      </router-link>
      <router-link to="/PanelMesas" class="col-4 text-decoration-none carrito" >
          <i class="fa-solid fa-chair fs-3 w-100 text-center text-nav"></i>
          <p class="button-text text-center mb-0 text-nav">Mesas</p>
      </router-link>
      <router-link to="/AccesoMesas" class="col-4 text-decoration-none carrito" >
          <i class="fa-solid fa-pen-to-square fs-3 w-100 text-center text-nav"></i>
          <p class="button-text text-center mb-0 text-nav">Apuntar</p>
      </router-link>
    </div>
  </footer>
  <LlamadaCamarero></LlamadaCamarero>
</template>

<script>
  import { mapState } from 'vuex';
  import axios from 'axios';
  import router from '@/router';
  import LlamadaCamarero from '@/components/LlamadaCamarero.vue'

  export default {
    name: 'App',
    data() {
      return {
        carrito: 0,
        loginsFallidos: 0,
        max_plates: null,
        isSidebarVisible: true
      }
    },
    components: {
      LlamadaCamarero
    },
    computed:{
        ...mapState(['isLoggedIn']),
        ...mapState(['token']),
        ...mapState(['partner_token']),
        ...mapState(['refresh']),
    },
    methods:{
      logout() {
        this.$store.commit('logout');
      },
      OpenCallWaiter(){
        console.log("abro llamar al camarero")
        document.getElementById("waiter_call").classList.remove("d-none")
      },
      async ReviseSession(){
        console.log("Token: " + this.token)
        console.log("Partner Token: " + this.partner_token)
        console.log("Refresh: " + this.refresh)
        console.log("Fallidos: " + this.loginsFallidos)
        if (this.$route.meta.authorized){
          if (this.token != "null" && this.token != null){
            const token = this.token; // Suponiendo que tienes el token almacenado en Vuex
            const url = process.env.VUE_APP_API_URL+'/v1/api/app/me_table';
            try {
              const response = await axios.get(url, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              });
              this.loginsFallidos = 0;
              console.log(response.data);
              this.carrito = response.data.shopping_cart_amount
              this.max_plates = response.data.max_plates
            } catch (error) {
              this.loginsFallidos++;
              if (this.loginsFallidos > 2) {
                router.push({ name: "Unauthorized" });
              }
              console.error('Error fetching shopping cart:', error);
            }
          } else {
            this.loginsFallidos++;
            if (this.loginsFallidos > 2) {
              router.push({ name: "Unauthorized" });
            }
          }
        }else if(this.$route.meta.showFooterWaiter || this.$route.meta.partnerMenu){
          console.log("estoy modo partner")
          console.log(this.partner_token)
          console.log(this.refresh)
          if (this.partner_token != "null" && this.partner_token != null){
            let url = process.env.VUE_APP_API_URL+'/v1/api/partner/verify';
            try {
              let token = {
                token: this.partner_token
              }
              const response = await axios.post(
                url,
                token,  // Aquí envías el `token` como cuerpo de la solicitud
                {
                  headers: {
                    'Content-Type': 'application/json'  // Establece los encabezados correctos si es necesario
                  }
                }
              );
              console.log(response.data)
            } catch (error) {
              url = process.env.VUE_APP_API_URL+'/v1/api/partner/refresh'
              try {
                let refresh = {
                  refresh: this.refresh
                }
                const response2 = await axios.post(
                  url,
                  refresh,  // Aquí envías el `token` como cuerpo de la solicitud
                  {
                    headers: {
                      'Content-Type': 'application/json'  // Establece los encabezados correctos si es necesario
                    }
                  }
                );
                console.log(response2.data)
                this.$store.dispatch('setToken', response2.data);
                console.log(this.partner_token)
              } catch (error) {
                console.error('Error refresh:', error);
                this.loginsFallidos++
                if (this.loginsFallidos > 5){
                  router.push({ name: "Login" });
                }
              }
              console.error('Error pair:', error);
            }
          }else{
            this.loginsFallidos++
            if (this.loginsFallidos > 5){
              router.push({ name: "Unauthorized" });
            }
          }
        }
      },
      startPolling() {
        this.ReviseSession()
        this.pollingInterval = setInterval(this.ReviseSession, 5000); // Cada 30 segundos
      },
      stopPolling() {
        clearInterval(this.pollingInterval);
      },
      toggleSidebar() {
        this.isSidebarVisible = !this.isSidebarVisible;
        document.body.classList.toggle('no-scroll', this.isSidebarVisible);
      }
    },
    startPolling() {
      this.ReviseSession()
      this.pollingInterval = setInterval(this.ReviseSession, 15000); // Cada 30 segundos
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
      document.body.classList.toggle('no-scroll', this.isSidebarVisible);
    },
  mounted() {
      /*this.$nextTick(() => {
          setTimeout(() => {
              const scrollPosition = window.scrollY || window.pageYOffset;
              const windowHeight = window.innerHeight;
              const documentHeight = document.documentElement.scrollHeight;
              const distanceToBottom = documentHeight - (scrollPosition + windowHeight);

              const page = document.getElementById("pagina");
              if (page) {
                  console.log(page);
                  page.style.paddingBottom = distanceToBottom + "px";
              }
          }, 1000); // 1000 milisegundos = 1 segundo
      });*/
    },
    created() {
      if (window.innerWidth <= 768) {
        this.isSidebarVisible = false;
      }
      

        this.startPolling();
      },
      beforeUnmount() {
        this.stopPolling();
      }
  };
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;500;600;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');


  * {
    font-family: 'Poppins', sans-serif;
    
  }
  body{
    background-color: #f5f5f5 !important;
  }

  .text-nav{
    color:#F47700;
  }

  footer{
    position: fixed;
    bottom: 0;
    width: 100vw; 
    height: 80px;
    
    z-index: 10;
    background-color: transparent;
  }

  .footer-rounded{
    position: fixed;
    z-index: 0;
    bottom: 0;
    width: 100vw; 
    height: 80px;
  }

  .page{
    position: fixed;
    /*height: 100vh !important;*/
    height: calc(100% - 80px);
    /*padding-bottom: 8vh;*/
    top:0;
    overflow-y: scroll;
    width: 100%;
    background-color: rgb(243, 243, 243) !important;
  }

  .phone-icon{
    position: fixed;
    bottom:70px;
    left: 44%;
    z-index: 1000;
    height: 50px;
    width: 12%;
    border-radius: 100px;
    background-color: white;
  }

  .powered{
    position: fixed;
    z-index: 10;
    bottom: 90px;
    right: 3vw;
    font-size: 0.7rem;
    pointer-events: none;
  }

  .powered span{
    color:#F47700;
    font-weight: bold;
  }

  .powered img{
    height: 18px;
    width: auto;
  }

  .amount-carrito{
    position:fixed;
    margin-left:12vw;
    margin-top: -11px;
    color: white;
    background-color: #F47700;
    border-radius: 100px;
  }

  nav,
    .offcanvas {
      background-color: #1e293b;
    }
    .navbar-toggler {
      border: none;
    }
    .navbar-toggler:focus {
      outline: none;
      box-shadow: none;
    }

    .selected{
      color: #F47700 !important;
      background-color: white;
    }

    .pagePartner{
      margin-left: 18% !important;
      width: 82% !important;
      padding: 0px 2% 0px 2%;
      position: fixed;
      height: 100% !important;
      overflow-y: scroll;
      width: 100%;
    }

    .no-link-style {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }

    .no-scroll {
      overflow: hidden;
    }

    @media (min-width: 1200px) {
      .phone-icon{
        left: 47%;
        height: 50px;
        width: 6%;
      }
    }

    @media (max-width: 1200px) {
      .header_partner{
        width: 28%;
      }
      .pagePartner{
        margin-left: 28% !important;
        width: 71% !important;
      }
    }

    @media (min-width: 767px) {
      .phone-icon{
        left: 46%;
        height: 50px;
        width: 8%;
      }
      
    }

    @media (max-width: 1170px) {
      #left-menu {
        width: 25%;
        transition: 3s !important;
      }
    }

    @media (max-width: 768px) {
      #left-menu {
        width: 50%;
        transition: 3s !important;
      }

      .powered{
        top: 10px;
        right: 10px;
      }

      .header_partner{
        width: 50%;
        padding: 5px;
      }

      .pagePartner{
        margin-left: 0% !important;
        width: 100% !important;
      }

      .header_partner .list-group li{
        font-size: 1rem;
      }

      .header_partner .list-group i {
        font-size: 1.2rem;
      }

      .logoPartner {
        margin-top: 3vh;
        max-width: 80%;
      }
    }

    @media (max-width: 576px) {
      .header_partner{
        width: 25%
      }
      .header_partner .list-group li {
        font-size: 0.9rem;
      }

      .header_partner .list-group i {
        font-size: 1rem;
      }

      .logoPartner {
        max-width: 90%;
        margin-top: 4vh;
      }
    }

    @media (max-width: 400px) {
      .header_partner{
        width: 30%
      }

      .logoPartner {
        max-width: 90%;
      }
    }

    .header_partner {
      width: 18%;
      position: fixed;
      top: 0;
      height: 100vh;
      z-index: 10;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }

    .header_partner .list-group {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .header_partner .list-group li {
      color: white;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      font-size: 1.2rem;
      white-space: nowrap;
    }

    .header_partner .list-group i {
      font-size: 1.4rem;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .header_partner .list-group li a {
      display: flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
    }
</style>